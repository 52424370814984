import React from "react";

const HydroeletricsSvgMarker = ({ fillColor }: { fillColor: string }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      filter="drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3))"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fillColor}
        stroke="none"
      >
        <path
          d="M2285 5105 c-608 -98 -1112 -472 -1376 -1019 -121 -251 -168 -447
-176 -731 -8 -273 25 -491 118 -782 183 -573 603 -1297 1188 -2048 230 -295
396 -487 443 -511 43 -22 129 -18 169 9 41 28 134 134 333 382 721 896 1205
1761 1351 2412 56 247 70 518 40 732 -110 772 -691 1386 -1460 1542 -143 29
-490 36 -630 14z m484 -936 c399 -92 691 -462 691 -874 0 -423 -290 -780 -715
-881 -93 -22 -277 -22 -370 0 -342 81 -590 317 -687 653 -33 113 -33 343 0
456 76 265 247 469 487 583 194 92 383 112 594 63z"
        />
      </g>
    </svg>
  );
};

export default HydroeletricsSvgMarker;
