import React from "react";
import { useState } from "react";
import ShowHidroeletricsButton from "./ShowHidroeletricsButton/ShowHidroeletricsButton";
import ShowStationsButton from "./ShowStationsButton/ShowStationsButton";
import styles from "./Navbar.module.css";
import { MapRef } from "react-map-gl";
import ShowPluviButton from "./ShowPluviButton/ShowPluviButton";
import { RxHamburgerMenu } from "react-icons/rx";
import ShowShapesButton from "./ShowShapesButton/ShowShapesButton";
import ShowChilePlantsButton from "./ShowChilePlants/ShowChilePlantsButton";
import { BsMenuButton } from "react-icons/bs";

const Navbar = ({
  toggleMarkers,
  toggleInmetMarkers,
  toggleGfs,
  toggleEcmwf,
  toggleSafiraMarkers,
  selectedHydroeletricsData,
  selectHydroeletricsDataToggle,
  selectedStationData,
  selectStationDataToggle,
  renderMarkers,
  renderInmetMarkers,
  setIntervalSelectedButton,
  toggleCemadenMarkers,
  selectedPluvi,
  selectedShapes,
  toggleSelectedShapes,
  instantCemadenData,
  setInstantCemadenData,
  currentDay,
  setCurrentDay,
  setStationDayValue,
  setAccumulatedRainData,
  setAccumulatedSixHour,
  accumulatedRainData,
  toggleChileMarkers,
  renderChileMarkers,
  selectHydroeletricsBowlToggle,
  selectHydroeletricsSubmarketToggle,
  selectedHydroeletricsSubmarket,
  selectHydroeletricsBowl,
}: {
  stationData: DataHidroeletricsType[];
  toggleMarkers: () => void;
  toggleInmetMarkers: () => void;
  toggleGfs: () => void;
  toggleEcmwf: () => void;
  toggleSafiraMarkers: () => void;
  setPopupData: (entry: DataHidroeletricsType) => void;
  reference: React.RefObject<MapRef>;
  dataHidroeletrics: DataHidroeletricsType[];
  setShowStation: (entry?: DataStationType) => void;
  changePopup(entry?: DataHidroeletricsType): void;
  showMarkers: DataHidroeletricsType[];
  changePopupInmet: (entry?: DataStationType) => void;
  selectedHydroeletricsData: string;
  selectHydroeletricsDataToggle: (entry: string) => void;
  selectedStationData: string;
  selectStationDataToggle: (entry: string) => void;
  renderMarkers: boolean;
  renderInmetMarkers: boolean;
  setIntervalSelectedButton: (entry: number) => void;
  toggleCemadenMarkers: () => void;
  selectedPluvi: boolean;
  selectedShapes: boolean;
  toggleSelectedShapes: () => void;
  instantCemadenData: CemadenDataType[] | undefined;
  setInstantCemadenData: (entry: CemadenDataType[] | undefined) => void;
  currentDay: currentHourDayType[] | undefined;
  setCurrentDay: (entry: currentHourDayType[] | undefined) => void;
  setStationDayValue: (entry: number) => void;
  accumulatedRainData: AccumulatedHourType[] | undefined;
  setAccumulatedRainData: (entry: AccumulatedHourType[] | undefined) => void;
  setAccumulatedSixHour: (entry: number) => void;
  toggleChileMarkers: () => void;
  renderChileMarkers: boolean;
  selectHydroeletricsBowlToggle: (entry: string) => void;
  selectHydroeletricsSubmarketToggle: (entry: string) => void;
  selectedHydroeletricsSubmarket: string;
  selectHydroeletricsBowl: string;
}) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const [produto, setProduto] = React.useState("");

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <>
      <div className={styles.navBar}>
        <button
          className={
            (styles.menuButton,
            navbarOpen ? styles.menuButtonActive : styles.menuButton)
          }
          onClick={handleToggle}
        >
          {/* <p>Menu</p> */}
          <div className={styles.iconContainer}>
            <RxHamburgerMenu />
          </div>
        </button>
        <div
          className={
            (styles.menuNav, navbarOpen ? styles.showMenu : styles.menuNav)
          }
        >
          {/* <div className={styles.selectModels}>
            <h3>Modelos</h3>
            <button className={styles.btnGfs} onClick={toggleGfs}>
              GFS
            </button>
            <button className={styles.btnGefs} disabled>
              GEFS
            </button>
            <button className={styles.btnEcmwf} onClick={toggleEcmwf}>
              ECMWF
            </button>
          </div> */}

          <div className={styles.selectHidroeletrics}>
            {/* <h3>Usinas Hidroelétricas</h3> */}
            <ShowHidroeletricsButton
              toggleMarkers={toggleMarkers}
              value={produto}
              selectedHydroeletricsData={selectedHydroeletricsData}
              selectHydroeletricsDataToggle={selectHydroeletricsDataToggle}
              renderMarkers={renderMarkers}
              toggleSafiraMarkers={toggleSafiraMarkers}
              selectHydroeletricsBowlToggle={selectHydroeletricsBowlToggle}
              selectHydroeletricsSubmarketToggle={
                selectHydroeletricsSubmarketToggle
              }
              selectedHydroeletricsSubmarket={selectedHydroeletricsSubmarket}
              selectHydroeletricsBowl={selectHydroeletricsBowl}
            />
          </div>

          {/* <h3>Estações Meteorológicas</h3> */}
          <div className={styles.selectStations}>
            <ShowStationsButton
              toggleInmetMarkers={toggleInmetMarkers}
              value={produto}
              selectedStationData={selectedStationData}
              selectStationDataToggle={selectStationDataToggle}
              renderInmetMarkers={renderInmetMarkers}
              setIntervalSelectedButton={setIntervalSelectedButton}
              currentDay={currentDay}
              setCurrentDay={setCurrentDay}
              setStationDayValue={setStationDayValue}
              setAccumulatedSixHour={setAccumulatedSixHour}
              accumulatedRainData={accumulatedRainData}
            />
          </div>

          <div className={styles.selectPluvio}>
            {/* <h3>Pluviômetros Automáticos</h3> */}
            <ShowPluviButton
              toggleCemadenMarkers={toggleCemadenMarkers}
              selectedPluvi={selectedPluvi}
            />
          </div>
          {/* Mostrar Shapes */}
          <div className={styles.selectShapesDiv}>
            <ShowShapesButton toggleSelectedShapes={toggleSelectedShapes} />
          </div>
          {/* Usinas Chile */}
          <div className={styles.selectChilePlants}>
            <ShowChilePlantsButton
              toggleChileMarkers={toggleChileMarkers}
              renderChileMarkers={renderChileMarkers}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
