import React, { useState, useEffect, useCallback } from "react";
import { MapRef } from "react-map-gl";
import "./SearchBar.css";
import { BsSearch } from "react-icons/bs";
import { GrClose } from "react-icons/gr";

export type SearchBarType =
  | DataHidroeletricsType
  | (DataStationType & {
      station_name: string;
      longitude: number;
      latitude: number;
    });

//Importação de funções e referencias de propriedades
const SearchBarHydroeletrics = ({
  reference,
  setPopupData,
  setShowStation,
  dataHidroeletrics,
  safiraUsinasData,
  showSafiraMarkers,
}: // dataStation,
{
  reference: React.RefObject<MapRef>;
  setPopupData: (entry: DataHidroeletricsType | PointerAndPopUp) => void;
  dataHidroeletrics: DataHidroeletricsType[];
  // dataStation: DataStation[];
  setShowStation: (entry: DataStationType) => void;
  safiraUsinasData: (PointerAndPopUp | Polygons)[];
  showSafiraMarkers: boolean;
}) => {
  //Estados que são atualizados
  const [inputValue, setInputValue] = useState("");
  const [filterSearch, setFilterSearch] = useState<DataHidroeletricsType[]>([]);
  const [filterSearchSafira, setFilterSearchSafira] = useState<
    (DataHidroeletricsType | PointerAndPopUp | Polygons)[]
  >([]);
  //Estado adicional para armazenar o item selecionado atualmente
  const [selectedItem, setSelectedItem] = useState<
    DataHidroeletricsType | PointerAndPopUp
  >();

  //junta os dois arrays com os valores de dois arrays, criando um outro tipo
  // const searchData = [
  //   ...dataHidroeletrics,
  //   ...dataStation.map((entry) => ({
  //     ...entry,
  //     station_name: entry.dcNome,
  //     latitude: entry.vlLatitude,
  //     longitude: entry.vlLongitude,
  //     station: entry.cdStation,
  //   })),
  // ];

  //junção de arrays
  const allData = [...filterSearch, ...filterSearchSafira];

  //Função que altera a posição quando feita a pesquisa e mostra o Popup do resultado
  const changePosition = (
    newPosition: DataHidroeletricsType | PointerAndPopUp
  ) => {
    //Atualiza o popupData apenas se o item selecionado for diferente do anterior
    if (newPosition !== selectedItem) {
      setPopupData(newPosition);
      setSelectedItem(newPosition);
    }

    const latitude = () => {
      if ("station_name" in newPosition) {
        return newPosition.latitude;
      }
      if ("properties" in newPosition) {
        return newPosition.geometry.coordinates[1];
      } else return -15.7;
    };

    const longitude = () => {
      if ("station_name" in newPosition) {
        return newPosition.longitude;
      }
      if ("properties" in newPosition) {
        return newPosition.geometry.coordinates[0];
      } else return -61.1;
    };

    const value1 = latitude();
    const value2 = longitude();

    //A função jumpTO é utilizada para o mapa renderizar a nova posição que vai ser exibida, ela é mais precisa do que a flyTo.
    reference.current?.jumpTo({
      center: [value2, value1],
    });

    reference.current?.setZoom(10);
  };

  const handleFilter = useCallback(() => {
    const newFilter = dataHidroeletrics
      .filter((value) => {
        const stationName = value.station_name.toLowerCase();
        const inputValueLower = inputValue.toLowerCase();

        // Compara se a string está no início da estação e em seguida ordena por proximidade com o input
        return stationName.indexOf(inputValueLower) === 0;
      })
      .sort((a, b) => {
        // Ordena por ordem alfabética e por proximidade com o input
        const stationA = a.station_name.toLowerCase();
        const stationB = b.station_name.toLowerCase();
        const inputValueLower = inputValue.toLowerCase();

        const distanceA = Math.abs(stationA.indexOf(inputValueLower));
        const distanceB = Math.abs(stationB.indexOf(inputValueLower));

        // Se a distância for a mesma, ordena alfabeticamente
        if (distanceA === distanceB) {
          return stationA.localeCompare(stationB);
        }

        return distanceA - distanceB;
      })
      // Remove valores repetidos
      .filter((value, index, self) => {
        return (
          self.findIndex((v) => v.station_name === value.station_name) === index
        );
      });

    const newFilterTwo = safiraUsinasData
      .filter((entry) => {
        const point = entry as PointerAndPopUp;

        const stationName = point.properties.name.toLowerCase();
        const inputValueLower = inputValue.toLowerCase();

        return stationName.indexOf(inputValueLower) === 0;
      })
      .sort((a, b) => {
        // Ordena por ordem alfabética e por proximidade com o input
        const stationA = a as PointerAndPopUp;
        const stationB = b as PointerAndPopUp;

        const stationAValue = stationA.properties.name.toLowerCase();
        const stationBValue = stationB.properties.name.toLowerCase();

        const inputValueLower = inputValue.toLowerCase();

        const distanceA = Math.abs(stationAValue.indexOf(inputValueLower));
        const distanceB = Math.abs(stationBValue.indexOf(inputValueLower));

        // Se a distância for a mesma, ordena alfabeticamente
        if (distanceA === distanceB) {
          return stationAValue.localeCompare(stationBValue);
        }

        return distanceA - distanceB;
      })
      .filter((value, index, self) => {
        const typeValue = value as PointerAndPopUp;
        const selfValue = self as PointerAndPopUp[];
        return (
          selfValue.findIndex(
            (v) => v.properties.name === typeValue.properties.name
          ) === index
        );
      });

    setFilterSearch(newFilter);
    setFilterSearchSafira(newFilterTwo);
  }, [dataHidroeletrics, inputValue, safiraUsinasData]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  //se tiver selecionado os dados das usinas ele vai pesquisar somente os dados das mesmas
  if (dataHidroeletrics && showSafiraMarkers === false) {
    return (
      <div className="firstDiv">
        <input
          className="searchBar"
          type="text"
          placeholder="Busque pelo nome da usina"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <span className="searchSpan">
          <span>{<BsSearch className="iconSearch" />}</span>
        </span>
        <button
          className="clearButton"
          onClick={() => {
            setInputValue("");
          }}
        >
          <div className="iconSearch">
            <span>{<GrClose />}</span>
          </div>
        </button>
        {inputValue && (
          <div className="dataResult">
            {filterSearch.map((value) => {
              return (
                <div
                  key={value.station_name}
                  className="dataItem"
                  onClick={(event) => {
                    event.stopPropagation();
                    changePosition(value);
                    setInputValue("");
                    setPopupData(value);
                  }}
                >
                  <p>{value.station_name}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  //se caso os dados das usinas hidroeletricas e também as fotovoltaicas da safira estiverem selecionados, ele vai pesquisar também as estações das safira junto com as outras

  //a verificação dos if verifica se existe uma propriedade dentro do valor da junção do array que tem três tipos, se caso tiver ele é um tipo, o que permite acessar o dado, podemos ler sobre no link: https://www.typescriptlang.org/docs/handbook/advanced-types.html
  if (dataHidroeletrics && showSafiraMarkers === true) {
    return (
      <div className="firstDiv">
        <input
          className="searchBar"
          type="text"
          placeholder="Pesquise pelo nome da usina"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <span className="searchSpan">
          <span>{<BsSearch className="iconSearch" />}</span>
        </span>
        <button
          className="clearButton"
          onClick={() => {
            setInputValue("");
            console.log(inputValue);
          }}
        >
          <span>{<GrClose />}</span>
        </button>

        {inputValue && (
          <div className="dataResult">
            {allData.map((value) => {
              //se tiver a propriedade station_name no valor que o map receber, ele vai retornar x elemento
              if ("station_name" in value) {
                return (
                  <div
                    key={value.station_name}
                    className="dataItem"
                    onClick={(event) => {
                      event.stopPropagation();
                      changePosition(value);
                      setInputValue("");
                      setPopupData(value);
                    }}
                  >
                    <p>{value.station_name}</p>
                  </div>
                );
              }
              if ("properties" in value) {
                return (
                  <div
                    key={value.properties.name}
                    className="dataItem"
                    onClick={(event) => {
                      event.stopPropagation();
                      changePosition(value);
                      setInputValue("");
                      setPopupData(value);
                    }}
                  >
                    <p>{value.properties.name}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SearchBarHydroeletrics;
