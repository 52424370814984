import styles from "./ShowPluviButton.module.css";

const ShowPluviButton = ({
  toggleCemadenMarkers,
  selectedPluvi,
}: {
  toggleCemadenMarkers: () => void;
  selectedPluvi: boolean;
}) => {
  return (
    <>
      <button
        type="button"
        className={
          selectedPluvi === true
            ? styles.selectedPluviButton
            : styles.showPluviButton
        }
        onClick={() => toggleCemadenMarkers()}
      >
        Pluviômetros Automáticos
      </button>
    </>
  );
};

export default ShowPluviButton;
