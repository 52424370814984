import "./App.css";
import { AuthProvider, AuthProviderProps, User } from "oidc-react";
import { toast } from "react-toastify";
import Routes from "components/Nav/Routes/Routes";

//variável que pega os parametros da url com a propriedade user
const urlUser = new URLSearchParams(window.location.search).get("user");

//configurações do sistema oidc de login
export const oidcConfig: AuthProviderProps = {
  //provedor de login zitadel, configura quem vai fazer a autenticação
  authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
  //id do cliente, é o id do próprio zitadel
  clientId: process.env.REACT_APP_ZITADEL_CLIENT_ID,
  responseType: "code",
  scope: "openid profile email",
  //url que o usuário após logar vai ser direcionado, nesse caso é localmente
  redirectUri: process.env.REACT_APP_ZITADEL_REDIRECT_URI,
  //se possivel faz login automáticamente
  autoSignIn: true,

  //se existir a url com o usuário, ele automaticamente é enviada ao zitadel, fazendo login sozinho.
  extraQueryParams: urlUser ? { login_hint: urlUser } : undefined,

  //quando o usuário logar, ele vai fazer um evento, no caso vai dar um alerta de bem vindo
  async onSignIn(response: User | null) {
    toast.dismiss();

    toast.success(`👋🏼 Olá ${response?.profile.name}`, {
      position: "top-right",

      icon: false,

      hideProgressBar: true,

      style: { marginTop: "80px" },

      bodyStyle: { fontSize: "1.015rem" },
    });

    const nextURL = "/";

    const nextTitle = "Boas vindas ao Mapa dinâmico do Safira On!";

    const nextState = {
      additionalInformation: "Boas vindas ao Mapa dinâmico do Safira On!",
    };

    window.history.pushState(nextState, nextTitle, nextURL);
  },
};

function App() {
  return (
    <AuthProvider {...oidcConfig}>
      <Routes />
    </AuthProvider>
  );
}

export default App;
