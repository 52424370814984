import React, { useEffect, useState } from "react";
import styles from "./CemadenTimeRange.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./CustomSlider.css";

export type currentHourDayType = {
  stationCode: string;
  dateTime: string;
};

const rainButtons = [
  { label: "Inst", value: 1 },
  // { label: "6h", value: 2 },
  // { label: "12h", value: 3 },
];

//função que formata a data e o dia
function formatDate(dateString: string | undefined): string | undefined {
  if (dateString) {
    const date = new Date(dateString);
    const targetOffset = -178; // deslocamento horário de 2 horas e 58 minutos
    const currentOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(
      date.getTime() - (currentOffset - (targetOffset / 60) * -1) * 60000
    );
    const day = adjustedDate.getUTCDate().toString().padStart(2, "0");
    const month = (adjustedDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = adjustedDate.getUTCFullYear();
    const hours = adjustedDate.getUTCHours().toString().padStart(2, "0");
    const minutes = adjustedDate.getUTCMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} às ${hours}:${minutes}`;
  }
}
const CemadenTimeRange = ({
  cemadenData,
  instantCemadenData,
  setInstantCemadenData,
  cemadenDayValue,
  setCemadenDayValue,
  cemadenSelectedButton,
  setCemadenSelectedButton,
  cemadenTwentyFourHours,
}: {
  cemadenData: CemadenDataType[] | undefined;
  instantCemadenData: CemadenDataType[] | undefined;
  setInstantCemadenData: (entry: CemadenDataType[] | undefined) => void;
  cemadenDayValue: number;
  setCemadenDayValue: (entry: number) => void;
  cemadenSelectedButton: number | null;
  setCemadenSelectedButton: (entry: number | null) => void;
  cemadenTwentyFourHours: cemadenDateTime[] | undefined;
}) => {
  const day = cemadenTwentyFourHours?.[cemadenDayValue];

  // Função que pega o dado QUE VEM EM GMT e formata somente a data PARA UTC
  function formatData(dado: string): string {
    const dataHora = new Date(dado);
    dataHora.setUTCHours(dataHora.getUTCHours() - 2); // Subtrai 2 horas
    const dia = dataHora.getUTCDate().toString().padStart(2, "0");
    const mes = (dataHora.getUTCMonth() + 1).toString().padStart(2, "0");
    const ano = dataHora.getUTCFullYear().toString();

    return `${dia}/${mes}/${ano}`;
  }

  // Função que pega do dado QUE VEM EM GMT somente o horário e o formata PARA UTC-3
  function formatHour(dado: string): string {
    const dataHora = new Date(dado);

    dataHora.setUTCHours(dataHora.getUTCHours() - 2); // Subtrai 2 horas
    const hora = dataHora.getUTCHours().toString().padStart(2, "0");

    return `${hora}h`;
  }

  const showDay = () => {
    if (cemadenTwentyFourHours && day) {
      return (
        <span>
          Dia: {formatData(day.dateTime)} às {formatHour(day.dateTime)}
        </span>
      ); // Adapte o retorno conforme necessário
    }
    return null; // Retorne null ou outro elemento vazio caso `cemadenTwentyFourHours` não esteja definido
  };

  return (
    <>
      {/* Intervalo de botões */}
      {/* <div className={styles.TimeIntervalButtons}>
        <p>Chuva Acumulada</p>
        {rainButtons.map((button) => (
          <button
            key={button.value}
            style={
              cemadenSelectedButton === button.value
                ? {
                    background: "#bee6ff",
                    color: "#032677",
                  }
                : {}
            }
            className={
              cemadenSelectedButton === button.value &&
              styles.timeIntervalSelectedButton
            }
            onClick={() => {
              setCemadenSelectedButton(button.value);
            }}
          >
            {button.label}
          </button>
        ))}
      </div> */}

      <div className={styles.timeRange}>
        <h2 style={{ color: "#032677", marginBottom: "5px" }}> {showDay()}</h2>
        <p
          style={{
            margin: "0px",
            border: "0px",
            padding: "0px",
            fontSize: "12px",
          }}
        >
          Acumulação pluviométrica das últimas 24 horas, desagregada por cada
          hora.
        </p>
        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
          Escolha o horário:
        </p>

        <Slider
          min={0}
          max={cemadenTwentyFourHours && cemadenTwentyFourHours?.length - 1}
          step={1}
          marks={Object.fromEntries(
            cemadenTwentyFourHours?.map((value, index) => [
              index,
              {
                label: formatHour(value.dateTime),
              },
            ]) ?? []
          )}
          defaultValue={cemadenDayValue}
          onChange={(value) => {
            if (Array.isArray(value)) return;
            setCemadenDayValue(value);
          }}
          handleStyle={{
            backgroundColor: "#031e4c",
            borderColor: "#031e4c",
          }}
          trackStyle={{
            backgroundColor: "#578B18",
            padding: "3px",
          }}
          railStyle={{ backgroundColor: "#031e4c" }}
          dotStyle={{ backgroundColor: "#031e4c" }}
          activeDotStyle={{ backgroundColor: "#578B18" }}
        />
      </div>
    </>
  );
};

export default CemadenTimeRange;
