import React from "react";
import { NavigationControl } from "react-map-gl";
import styles from "./ZoomInOut.module.css";

export const ZoomInOut = () => {
  return (
    <NavigationControl
      showCompass={false}
      style={{
        backgroundColor: "#032677",
        color: "white",
        backgroundImage: "none",
      }}
    />
  );
};
