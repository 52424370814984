import React, { useState, useEffect } from "react";
import styles from "./HydroeletricsTimeRange.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

export type IntervalTimeRangeType = {
  date: string;
};

const HydroeletricsTimeRange = ({
  changeDayValue,
  hydroeletricDayValue,
  rdhData,
  timeRange,
}: {
  hydroeletricDayValue: number;
  changeDayValue: (entry: number) => void;
  rdhData: DataRdhType[] | undefined;
  timeRange: IntervalTimeRangeType[];
}) => {
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const correct = new Date(date.getTime() + 86400000);
    return correct.toLocaleDateString("pt-br");
  }

  return (
    <div className={styles.HydroeletricsTimeRange}>
      <label>
        <h2 style={{ color: "#032677" }}>Linha do tempo (7 dias)</h2>
      </label>
      <br />
      <p style={{ fontWeight: "bold", fontSize: "12px" }}>Escolha o dia:</p>
      <Slider
        min={0}
        max={6}
        step={1}
        value={hydroeletricDayValue}
        marks={Object.fromEntries(
          //o marcador somente aceita objetos com o seu valor de index
          //e logo depois seu retorno, sendo esle um ReactComp ou outros valores
          //como style e label, então no código abaixo estamos pegando o
          //timerange, efetuando um map, pegando seu valor e index e retornando
          //um array com o valor do index primeiro e depois o dado a ser exibido!
          timeRange.map(({ date }, index) => [
            index,
            {
              label: formatDate(date),
              style: { fontWeight: "bold" },
            },
          ])
        )}
        onChange={
          //dentro do onChange da biblioteca ela recebe uma função
          //apenas uma função, então passamos uma função anonima que
          //recebe um valor, e depois vai usar o narrowing, que vai
          //eliminar a possibilidade do valor ser um array de numeros
          //sobrando apenas a possibilidade de de ser um numero, que
          //é passado na função changeDayValue que apenas aceita numero!
          (value) => {
            if (Array.isArray(value)) return;
            changeDayValue(value);
          }
        }
        handleStyle={{
          backgroundColor: "#031e4c",
          borderColor: "#031e4c",
        }}
        trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
        railStyle={{ backgroundColor: "#031e4c" }}
        dotStyle={{ backgroundColor: "#031e4c" }}
        activeDotStyle={{ backgroundColor: "#578B18" }}
      />
    </div>
  );
};

export default HydroeletricsTimeRange;
