import styles from "../PowerPlantSafira/PowerPlantSafira.module.css";
import { Marker, Source, Layer, LineLayer } from "react-map-gl";
import { useMemo } from "react";

//marcadores das usinas da safira com seus shapes
const PowerPlantSafira = ({
  showSafiraMarkers,
  selectedUsina,
  setSelectedUsina,
  safiraUsinasFotovoltaicas,
  setSafiraUsinasFotovoltaicas,
  safiraUsinasData,
  setSafiraUsinasData,
  setMarkerClicked,
  toggleSafiraMarkers,
  setShowSafiraPopUp,
}: {
  showSafiraMarkers: boolean;
  selectedUsina: PointerAndPopUp | undefined;
  setSelectedUsina: (entry: PointerAndPopUp | undefined) => void;
  safiraUsinasFotovoltaicas: string;
  setSafiraUsinasFotovoltaicas: (entry: string) => void;
  safiraUsinasData: (PointerAndPopUp | Polygons)[];
  setSafiraUsinasData: (entry: (PointerAndPopUp | Polygons)[]) => void;
  setMarkerClicked: (entry: PointerAndPopUp | undefined) => void;
  toggleSafiraMarkers: () => void;
  setShowSafiraPopUp: (entry: boolean) => void;
}) => {
  const fotoVoltaicas = useMemo(() => {
    return safiraUsinasFotovoltaicas;
  }, [safiraUsinasFotovoltaicas]);

  const safiraPowerPlantsLayer: LineLayer = {
    id: "safiraPowerPlantsShapes",
    type: "line",
    paint: {
      "line-opacity": 1,
      "line-color": "#b4b",
    },
  };

  return (
    <>
      <Source type="geojson" data={fotoVoltaicas}>
        <Layer {...safiraPowerPlantsLayer} />
      </Source>

      {showSafiraMarkers &&
        safiraUsinasData
          .filter((entry) => entry.geometry.type === "Point")
          .map((entry) => {
            const point = entry as PointerAndPopUp;

            return (
              <>
                <Marker
                  key={point.properties.name}
                  longitude={point.geometry.coordinates[0]}
                  latitude={point.geometry.coordinates[1]}
                >
                  <button
                    //Sempre lembrar de quando usar o marcador, fazer a função de stopPropagation se não, o popUp não vai funcionar corretamente!
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedUsina(point);
                      setMarkerClicked(point);
                    }}
                  >
                    <img
                      className={styles.stationImage}
                      src="https://api-safira-on-prisma.safiraenergia.com.br/grupo_safira_icon.png"
                      alt=""
                      style={{
                        width: "30px",
                      }}
                    />
                  </button>
                  {/*  */}
                </Marker>
              </>
            );
          })}
    </>
  );
};

export default PowerPlantSafira;
