import React, { useState, useEffect, useCallback } from "react";
import { MapRef } from "react-map-gl";
import "./SearchBar.css";
import { BsSearch } from "react-icons/bs";
import { GrClose, GrNewWindow } from "react-icons/gr";

const SearchBarStation = ({
  reference,
  stationList,
  setPopupInmetData,
  popupInmetData,
  changePopupInmet,
}: {
  reference: React.RefObject<MapRef>;
  stationList: DataStationType[];
  setPopupInmetData: (entry?: DataStationType) => void;
  popupInmetData: DataStationType | undefined;
  changePopupInmet: (entry?: DataStationType) => void;
}) => {
  //estados que serão atualizados:
  const [inputValue, setInputValue] = useState("");
  const [filterSearch, setFilterSearh] = useState<DataStationType[]>([]);
  const [selectedItem, setSelectedItem] = useState<DataStationType | null>();

  //função que altera a posição quando feita a pesquisa e mostra o Pop'up do resultado
  const changePosition = (newPosition: DataStationType) => {
    //valores lat lon
    if (newPosition !== selectedItem) {
      changePopupInmet(newPosition);
      setSelectedItem(newPosition);
    }
    const latitude = newPosition.vlLatitude;
    const longitude = newPosition.vlLongitude;

    //jumpto para a camera do mapa ir ao local correto da nova posição
    reference.current?.jumpTo({ center: [longitude, latitude] });
    //quando pular a posição, o zoom estabelecido vai ser de 10
    reference.current?.setZoom(10);
  };

  //função de filtro de pesquisa
  const searchFilter = useCallback(() => {
    const newFilter = stationList
      .filter((value) => {
        const stationName = value.dcNome.toLowerCase();
        const inputValueLower = inputValue.toLowerCase();
        //compara se a string está no inicio da estação e em seguida ordena por proximidade com o input
        return stationName.indexOf(inputValueLower) === 0;
      })
      .sort((a, b) => {
        //Ordena por ordem alfabética e por proximidade com o input
        const stationA = a.dcNome.toLowerCase();
        const stationB = a.dcNome.toLowerCase();
        const inputValueLower = inputValue.toLowerCase();

        const distanceA = Math.abs(stationA.indexOf(inputValueLower));
        const distanceB = Math.abs(stationB.indexOf(inputValueLower));

        //se a distância for a mesma, ordena alfabeticamente
        if (distanceA === distanceB) {
          return stationA.localeCompare(stationB);
        }
        return distanceA - distanceB;
      })
      //remove valores repetidos
      .filter((value, index, self) => {
        return self.findIndex((v) => v.dcNome === value.dcNome) === index;
      });
    setFilterSearh(newFilter);
  }, [stationList, inputValue]);

  useEffect(() => {
    searchFilter();
  }, [searchFilter]);

  if (stationList) {
    return (
      <div className="firstDiv">
        <input
          className="searchBar"
          type="text"
          placeholder="Busque pelo nome da estação"
          onChange={(e) => {
            setInputValue(e.target.value);
            changePopupInmet(undefined);
          }}
          value={inputValue}
        />
        <span className="searchSpan">
          
        </span>
        <button
          className="clearButton"
          onClick={() => {
            setInputValue("");
            console.log(inputValue);
          }}
        >
          <div className="iconSearch">
            <span>{<GrClose />}</span>
          </div>
        </button>
        {inputValue && (
          <div className="dataResult">
            {filterSearch.map((value) => {
              return (
                <div
                  key={value.cdStation}
                  className="dataItem"
                  onClick={(event) => {
                    event.stopPropagation();
                    changePosition(value);
                    setInputValue("");
                    changePopupInmet(value);
                  }}
                >
                  <p>{value.dcNome}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SearchBarStation;
