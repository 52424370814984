import { useMemo } from "react";
import { Source, Layer, LineLayer } from "react-map-gl";

const Shapes = ({
  sinShapeData,
  selectedShapes,
}: {
  sinShapeData: string;
  selectedShapes: boolean;
}) => {
  const bacia = useMemo(() => {
    return sinShapeData;
  }, [sinShapeData]);

  const dataLayer: LineLayer = {
    id: "data",
    type: "line",
    paint: {
      "line-opacity": 1,
    },
  };


  return (
    <>
      {selectedShapes && (
        <Source type="geojson" data={bacia}>
          <Layer {...dataLayer} />
        </Source>
      )}
    </>
  );
};

export default Shapes;
