import { useState, useEffect } from "react";
import { Popup } from "react-map-gl";

type StationInmetPopupData = {
  entry?: DataStationType;
  changePopupInmet: (entry?: DataStationType) => void;
  stationInmetData: StationInmetDataType[] | undefined;
  setStationInmetData: (entry?: StationInmetDataType[]) => void;
  stationDayValue: number;
  setStationDayValue: (entry: number) => void;
};

// export type StationInmetDataType = {
//   cdStation: string;
//   hrMeasurement: string;
//   dtMeasurement: string;
//   wingDiretion: number;
//   rain: number;
//   preIns: number;
//   umdMax: number;
//   preMin: number;
//   preMax: number;
//   venVel: number;
//   ptoMin: number;
//   temMax: number;
//   radGlo: number;
//   ptoIns: number;
//   venRaj: number;
//   temIns: number;
//   umdIns: number;
//   temMin: number;
//   umdMin: number;
//   ptoMax: number;
// };

const StationInmetPopup = ({
  entry,
  changePopupInmet,
  stationInmetData,
  setStationInmetData,
  stationDayValue,
  setStationDayValue,
}: StationInmetPopupData) => {
  // const [stationInmetData, setStationInmetData] =
  //   useState<StationInmetDataType>();

  // useEffect(() => {
  //   if (!entry) return;
  //   Api.get("station-inmet", {
  //     params: {
  //       cdStation: entry.cdStation,
  //     },
  //   })
  //     .then((response) => setStationInmetData(response.data))
  //     .catch((err) => console.log(err));
  // }, [entry]);

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const correct = new Date(date.getTime() + 86400000);
    return correct.toLocaleDateString("pt-br");
  }

  function verifyDate() {
    if (stationInmetData?.[stationDayValue]?.dtMeasurement !== undefined) {
      return formatDate(stationInmetData?.[stationDayValue]?.dtMeasurement);
    }
  }

  //Função que mostra os dados do Inmet no Popup, por padrão ele mostra apenas
  //os dados de informações da estação
  function handleStationData(dataStation?: StationInmetDataType[] | undefined) {
    dataStation = stationInmetData;

    if (!dataStation?.[stationDayValue]) {
      return (
        <>
          <p style={{ color: "red", fontWeight: "bold" }}>
            * Sem dados Disponiveis *
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>Data: {verifyDate()}</p>
          <p>Chuva: {stationInmetData?.[stationDayValue]?.rain}</p>
          <p>Temp Min: {stationInmetData?.[stationDayValue]?.temMin}</p>
          <p>Temp Max: {stationInmetData?.[stationDayValue]?.temMax}</p>
        </>
      );
    }
  }

  return (
    <>
      {entry && (
        <Popup
          longitude={entry.vlLongitude}
          latitude={entry.vlLatitude}
          onClose={() => changePopupInmet(undefined)}
          style={{ borderRadius: "5px" }}
          closeOnMove={true}
          closeButton={false}
        >
          <h4>{entry.dcNome}</h4>
          <p>Código da Estação: {entry.cdStation}</p>
          {/* <>{handleStationData(stationInmetData)}</> */}
        </Popup>
      )}
    </>
  );
};

export default StationInmetPopup;
