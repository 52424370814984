import { useState, useCallback, useEffect } from "react";
import { MapRef } from "react-map-gl";
import "./SearchBar.css";
import { BsSearch } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { Feature, FeatureCollection, GeoJsonProperties, Point } from "geojson";

const SearchBarCemaden = ({
  reference,
  cemadenData,
  popupCemaden,
  setPopupCemaden,
  changePopupCemaden,
}: {
  reference: React.RefObject<MapRef>;
  cemadenData: CemadenDataType[] | undefined;
  popupCemaden: GeoJsonProperties | undefined;
  setPopupCemaden: (entry: GeoJsonProperties | undefined) => void;
  changePopupCemaden: (entry: CemadenDataType | undefined) => void;
}) => {
  //estados que serão atualizados:
  const [inputValue, setInputValue] = useState("");
  const [filterSearch, setFilterSearch] = useState<CemadenDataType[]>([]);
  const [selectedItem, setSelectedItem] = useState<CemadenDataType | null>();

  //função que altera a posição quando feita a pesquisa e mostra o Pop'up do resultado
  const changePosition = (newPosition: CemadenDataType) => {
    //valores lat lon
    if (newPosition !== selectedItem) {
      setSelectedItem(newPosition);
    }
    const latitude = newPosition.latitude;
    const longitude = newPosition.longitude;

    //jumpto para a camera do mapa ir ao local correto da nova posição
    reference.current?.jumpTo({ center: [longitude, latitude] });
    //quando pular a posição, o zoom estabelecido vai ser de 10
    reference.current?.setZoom(10);
  };

  //função de filtro de pesquisa
  const searchFilter = useCallback(() => {
    if (cemadenData) {
      const newFilter = cemadenData
        .filter((value) => {
          const stationName = value.stationName.toLowerCase();
          const inputValueLower = inputValue.toLowerCase();
          //compara se a string está no inicio da estação e em seguida ordena por proximidade com o input
          return stationName.indexOf(inputValueLower) === 0;
        })
        .sort((a, b) => {
          //Ordena por ordem alfabética e por proximidade com o input
          const stationA = a.stationName.toLowerCase();
          const stationB = a.stationName.toLowerCase();
          const inputValueLower = inputValue.toLowerCase();

          const distanceA = Math.abs(stationA.indexOf(inputValueLower));
          const distanceB = Math.abs(stationB.indexOf(inputValueLower));

          //se a distância for a mesma, ordena alfabeticamente
          if (distanceA === distanceB) {
            return stationA.localeCompare(stationB);
          }
          return distanceA - distanceB;
        })
        //remove valores repetidos
        .filter((value, index, self) => {
          return (
            self.findIndex((v) => v.stationName === value.stationName) === index
          );
        });
      setFilterSearch(newFilter);
    }
  }, [cemadenData, inputValue]);

  useEffect(() => {
    searchFilter();
  }, [searchFilter]);

  if (cemadenData) {
    return (
      <div className="firstDiv">
        <input
          className="searchBar"
          type="text"
          placeholder="Busque pelo nome do pluviômetro"
          onChange={(e) => {
            setInputValue(e.target.value);
            changePopupCemaden(undefined);
          }}
          value={inputValue}
        />
        <span className="searchSpan">
          <span>{<BsSearch className="iconSearch" />}</span>
        </span>
        <button
          className="clearButton"
          onClick={() => {
            setInputValue("");
          }}
        >
          <div className="iconSearch">
            <span>{<GrClose />}</span>
          </div>
        </button>
        {inputValue && (
          <div className="dataResult">
            {filterSearch.map((value) => {
              return (
                <div
                  key={value.stationId}
                  className="dataItem"
                  onClick={(event) => {
                    event.stopPropagation();
                    changePosition(value);
                    setInputValue("");
                    setPopupCemaden(value);
                  }}
                >
                  <p>{value.stationName}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SearchBarCemaden;
