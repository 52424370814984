import React from "react";
import styles from "./ShowStationsButton.module.css";

type ShowStationButtonType = {
  value: string;
  toggleInmetMarkers: () => void;
  selectedStationData: string;
  selectStationDataToggle: (entry: string) => void;
  renderInmetMarkers: boolean;
  setIntervalSelectedButton: (entry: number) => void;
  currentDay: currentHourDayType[] | undefined;
  setCurrentDay: (entry: currentHourDayType[] | undefined) => void;
  setStationDayValue: (entry: number) => void;
  setAccumulatedSixHour: (entry: number) => void;
  accumulatedRainData: AccumulatedHourType[] | undefined;
};

const ShowStationsButton = ({
  toggleInmetMarkers,
  value,
  selectedStationData,
  selectStationDataToggle,
  renderInmetMarkers,
  setIntervalSelectedButton,
  currentDay,
  setCurrentDay,
  setStationDayValue,
  setAccumulatedSixHour,
  accumulatedRainData,
}: ShowStationButtonType) => {
  let backgroundColor = "#032677";
  let color = "#ffffff";

  if (renderInmetMarkers === true) {
    backgroundColor = "#a7c5d8";
    color = "#032677";
  }

  return (
    <>
      <div className={styles.divStationsData}>
        <button
          type="button"
          onClick={() => {
            toggleInmetMarkers();
            if (currentDay) {
              setStationDayValue(currentDay.length - 1);
            }
          }}
          className={styles.showStationsButton}
          style={{ backgroundColor, color }}
        >
          Estações Meteorológicas
        </button>

        <p className={styles.descriptionText}>Escolha um dado:</p>
        <select
          name="stationOptions"
          id="stationOptions"
          defaultValue={"rain"}
          onChange={(event) => {
            selectStationDataToggle(event.target.value);
            setIntervalSelectedButton(1);
          }}
          className={styles.selectedData}
        >
          <option value="" style={{ color: "grey" }}>
            Nenhum
          </option>
          <option value="rain">Chuva (mm)</option>
          <option value="tmpmin">Temp.Mín(°C)</option>
          <option value="tmpmax">Temp.Máx(°C)</option>
        </select>
      </div>
    </>
  );
};

export default ShowStationsButton;
