import React, { useEffect, useState } from "react";
import { Marker, MapRef } from "react-map-gl";
import "./HydroeletricsMarkers.css";
import HydroeletricsTimeRange from "../Navbar/HydroeletricsTimeRange/HydroeletricsTimeRange";
import SearchBarHydroeletrics from "../SearchBar/SearchBarHydroeletrics";
import HydroeletricsSvgMarker from "./HydroeletricsSvgMarker";

const HydroeletricsMarkers = ({
  setPopupData,
  stationList,
  changeDayValue,
  hydroeletricDayValue,
  rdhData,
  hydroeletricsMarkersData,
  selectedHydroeletricsData,
  mapRef,
  showMarkers,
  changePopupInmet,
  safiraUsinasData,
  showSafiraMarkers,
  timeRange,
  selectedHydroeletricsSubmarket,
  selectHydroeletricsBowl,
}: {
  setPopupData: (entry?: DataHidroeletricsType | PointerAndPopUp) => void;
  stationList: DataHidroeletricsType[];
  changeDayValue: (entry: number) => void;
  hydroeletricDayValue: number;
  rdhData: DataRdhType[] | undefined;
  hydroeletricsMarkersData: DataRdhType[] | undefined;
  selectedHydroeletricsData: string;
  mapRef: React.RefObject<MapRef>;
  showMarkers: DataHidroeletricsType[];
  changePopupInmet: (entry: DataStationType) => void;
  safiraUsinasData: (PointerAndPopUp | Polygons)[];
  showSafiraMarkers: boolean;
  timeRange: IntervalTimeRangeType[];
  selectedHydroeletricsSubmarket: string;
  selectHydroeletricsBowl: string;
}) => {
  return (
    <>
      <HydroeletricsTimeRange
        timeRange={timeRange}
        changeDayValue={changeDayValue}
        hydroeletricDayValue={hydroeletricDayValue}
        rdhData={rdhData}
      />
      <SearchBarHydroeletrics
        setPopupData={setPopupData}
        reference={mapRef}
        dataHidroeletrics={showMarkers}
        setShowStation={changePopupInmet}
        safiraUsinasData={safiraUsinasData}
        showSafiraMarkers={showSafiraMarkers}
      />
      {/* Renderiza os marcadores no mapa com base nas seleções de submercado e bacia */}
      {stationList.map((entry) => {
        if (
          (selectHydroeletricsBowl === "all" ||
            entry.bowl === selectHydroeletricsBowl) &&
          (selectedHydroeletricsSubmarket === "all" ||
            entry.subsystem === selectedHydroeletricsSubmarket)
        ) {
          return (
            <Marker
              key={entry.station}
              longitude={entry.longitude}
              latitude={entry.latitude < -90 ? -90 : entry.latitude}
              anchor="bottom"
              onClick={(event) => {
                event.originalEvent.stopPropagation();
                setPopupData(entry);
              }}
            >
              <button
              //ao passar do mouse, mostra o popup
              // onMouseEnter={() => {setPopupData(entry)}}
              // onMouseLeave={() => setPopupData(undefined)}
              >
                {/* Renderiza os marcadores */}
                {(() => {
                  //Marcadores Vazão natural
                  if (selectedHydroeletricsData === "vzn") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.naturalFlow;

                    if (!dataValue) return null;

                    let background = "#bee6ff";
                    let color = "black";

                    if (dataValue > 1000) {
                      background = "#0554d3";
                      color = "white";
                    }
                    if (dataValue > 5000) background = "#032677";
                    if (dataValue > 10000) background = "#00123b";

                    return (
                      <div
                        className="naturalFlow"
                        style={{ background, color }}
                      >
                        <p>{dataValue}</p>
                      </div>
                    );
                  }
                  //Marcadores Vazão Defluente
                  if (selectedHydroeletricsData === "vzd") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.effluentFlow;

                    if (!dataValue) return null;

                    let background = "#ffd766";
                    let color = "black";

                    if (dataValue > 1000) {
                      background = "#c6a750";
                    }
                    if (dataValue > 2000) {
                      background = "#98803d";
                    }
                    if (dataValue > 5000) {
                      background = "#5c4d24";
                      color = "white";
                    }

                    return (
                      <div
                        className="effluentFlow"
                        style={{ background, color }}
                      >
                        <p>{dataValue}</p>
                      </div>
                    );
                  }

                  //Marcadores de Vazão Turbinada
                  if (selectedHydroeletricsData === "vzt") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.turbochargedFlow;

                    if (!dataValue) return null 

                    let background = "#cd7ef5";
                    let color = "black";

                    if (dataValue > 1000) {
                      background = "#9056ad";
                      color = "white";
                    }
                    if (dataValue > 4000) {
                      background = "#683e7d";
                    }
                    if (dataValue > 9000) {
                      background = "#4d2c5d";
                    }

                    return (
                      <div
                        className="turbochargedFlow"
                        style={{ background, color }}
                      >
                        <p>{dataValue}</p>
                      </div>
                    );
                  }

                  if (selectedHydroeletricsData === "vzv") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.spilledFlow;

                    if (!dataValue) return null;

                    let background = "#00ffff";
                    let color = "black";

                    if (dataValue > 100) {
                      background = "#00aaaa";
                    }
                    if (dataValue > 500) {
                      background = "#007474";
                      color = "white";
                    }
                    if (dataValue > 1000) {
                      background = "#005050";
                    }
                    if (dataValue > 2000) {
                      background = "#002d2d";
                    }

                    return (
                      <div
                        className="spilledFlow"
                        style={{ background, color }}
                      >
                        <p>{dataValue}</p>
                      </div>
                    );
                  }

                  //Marcadores Volume Armazenados
                  if (selectedHydroeletricsData === "vlm") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.storedVolume;

                    if (!dataValue) return null;

                    let background = "#ff9852";
                    let color = "black";

                    if (dataValue > 50) {
                      background = "#c0713d";
                    }
                    if (dataValue > 75) {
                      background = "#834d29";
                      color = "white";
                    }
                    if (dataValue > 100) {
                      background = "#52301a";
                      color = "white";
                    }

                    return (
                      <div
                        className="storedVolume"
                        style={{ background, color }}
                      >
                        <p>
                          {dataValue
                            .toLocaleString("pt-BR", {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })
                            .replace(".", ",")}
                        </p>
                      </div>
                    );
                  }

                  //Marcadores do Nivel
                  if (selectedHydroeletricsData === "nvl") {
                    const dataValue = hydroeletricsMarkersData?.filter(
                      (data) => {
                        return data.station === entry.station;
                      }
                    )[hydroeletricDayValue]?.level;

                    if (!dataValue) return null;

                    let background = "#a1e44d";
                    let color = "black";

                    if (dataValue > 200) {
                      background = "#73a337";
                    }
                    if (dataValue > 500) {
                      background = "#557929";
                      color = "white";
                    }
                    if (dataValue > 700) {
                      background = "#334819";
                      color = "white";
                    }

                    return (
                      <div className="level" style={{ background, color }}>
                        <p>
                          {dataValue
                            .toLocaleString("pt-BR", {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })
                            .replace(".", ",")}
                        </p>
                      </div>
                    );
                  }
                  //caso não tenha nenhum dado selecionado, exibe icone de posto
                  if (selectedHydroeletricsData === "") {
                    //o index do objeto pode ser uma string, e não algo setado em hardcode
                    const bowls: { [index: string]: string } = {
                      Tietê: "red",
                      Paraná: "#33854a",
                      Paranapanema: "#7c3592",
                      Grande: "#cccccc",
                      "Paraíba do Sul": "#d698ad",
                      Doce: "#7ccfa9",
                      Paranaíba: "#f4b400",
                      Itabapoana: "#3f5ba9",
                      Amazonas: "#7ccfa9",
                      "São Francisco (Sudeste/Centro-Oeste)": "#9fc3ff",
                      Paraguai: "#c6a4cf",
                      "Jequitinhonha (Sudeste/Centro-Oeste)": "#795046",
                      Mucuri: "#f9f7a6",
                      "Tocantins (Sudeste/Centro-Oeste)": "#b7dbab",
                      Iguaçu: "#0ba9cc",
                      Uruguai: "#f4eb37",
                      Itajaí: "#777777",
                      Jacuí: "#b7dbab",
                      Capivari: "#d698ad",
                      Jequitinhonha: "#a61b4a",
                      "São Francisco (Nordeste)": "#c6a4cf",
                      Paraguaçu: "#62af44",
                      "Teles Pires": "#93d7e8",
                      "Tocantins (Norte)": "#62af44",
                      Araguari: "#db4436",
                      Madeira: "#ff00d4",
                      Xingu: "#b29189",
                      "Jequitinhonha (Nordeste)": "#f9f7a6",
                    };

                    return (
                      <HydroeletricsSvgMarker fillColor={bowls[entry.bowl]} />
                    );
                  }
                })()}
              </button>
            </Marker>
          );
        }
        return null;
      })}
      ;
    </>
  );
};

export default HydroeletricsMarkers;
