import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  TooltipProps,
} from "recharts";
import styles from "./SafiraUfv.module.css";
import { RiCloseFill } from "react-icons/ri";
import { Api } from "services/api";

//interface que representa os dados das ufv's do pv
interface PowerAndIrradianceType {
  power: {
    name: string;
    id: number;
    tsRead: string;
    sumPac: number;
  }[];
  irradiance: {
    name: string;
    id: number;
    tsRead: string;
    irradiance: number;
  }[];
}

//data atual
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

export const SafiraUfv = ({
  markerClicked,
  setMarkerClicked,
}: {
  markerClicked: PointerAndPopUp | undefined;
  setMarkerClicked: (entry: PointerAndPopUp | undefined) => void;
}) => {
  const [showChart, setShowChart] = useState(true);
  const [powerAndIrradiance, setPowerAndIrradiance] =
    useState<PowerAndIrradianceType>({ irradiance: [], power: [] });
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const joinPowerIrradianceHours = [
    ...powerAndIrradiance.irradiance.flatMap((hour) => hour.tsRead),
    ...powerAndIrradiance.power.flatMap((hour) => hour.tsRead),
  ];

  useEffect(() => {
    Api.get("power-and-irradiance", {
      params: {
        date: selectedDate,
        id: 230,
      },
    })
      .then((response) => setPowerAndIrradiance(response.data))
      .catch((err) => console.log(err));
  }, [selectedDate]);

  const mergedData = Array.from(new Set(joinPowerIrradianceHours))
    .sort((a, b) => Date.parse(a) - Date.parse(b))
    .map((entry) => ({
      time: entry.slice(11, 16),
      sumPac: powerAndIrradiance.power.find(
        (entry2) => entry2.tsRead === entry
      ),
      irradiance: powerAndIrradiance.irradiance.find(
        (entry2) => entry2.tsRead === entry
      ),
    }));

  //componente que renderiza o pop-up do tooltip
  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload[0]) {
      console.log(payload);
      return (
        <div
          className="custom-tooltip"
          style={{ padding: "12px", background: "#f1f1f1" }}
        >
          <h4 className="label">{`Horário: ${label}`}</h4>
          {payload[0].payload.sumPac && (
            <p
              className="label"
              style={{ color: "#E88FB4 " }}
            >{`Potência: ${payload[0].payload.sumPac?.sumPac} (kW)`}</p>
          )}
          {payload[0].payload.irradiance && (
            <p
              className="label"
              style={{ color: "#032677" }}
            >{`Irradiância: ${payload[0].payload.irradiance?.irradiance} (W/m²)`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  if (markerClicked?.properties.name === "UFV Montes Claros") {
    return (
      <div className={styles.overlay}>
        <div className={styles.container}>
          {showChart && (
            <div className={styles.chartContainer}>
              <div className={styles.descriptionGrid}>
                {/* <h1>{markerClicked?.properties.name}</h1> */}
                <h1>Painel de operação | Usina SAFIRA UFV001</h1>

                <div className={styles.inputContainer}>
                  <h3>
                    Dia:
                    <input
                      type="date"
                      className={styles.dateSelector}
                      value={selectedDate}
                      onChange={(event) => {
                        setSelectedDate(event.target.value);
                      }}
                    />
                  </h3>
                  <button
                    className={styles.clearButton}
                    onClick={() => setMarkerClicked(undefined)}
                  >
                    <RiCloseFill />
                  </button>
                </div>
              </div>

              {/* conteineres com outros dados */}
              {/* <div className={styles.squareContainer}>
                <div className={styles.squareWrapper}>
                  <p>Potência de Painel</p>
                  <div className={styles.square}>
                    <img
                      src="https://prisma.safiraenergia.com.br:3333/images/mapstyles/icones/Potência de Painel.png"
                      alt=""
                    />
                    <p>1328,40 kW</p>
                  </div>
                </div>
                <div className={styles.squareWrapper}>
                  <p>Potência do inversor</p>
                  <div className={styles.square}>
                    <img
                      src="https://prisma.safiraenergia.com.br:3333/images/mapstyles/icones/Potência do inversor.png"
                      alt=""
                    />
                    <p>1000 kW</p>
                  </div>
                </div>
                <div className={styles.squareWrapper}>
                  <p>Total gerado no dia</p>
                  <div className={styles.square}>
                    <img
                      src="https://prisma.safiraenergia.com.br:3333/images/mapstyles/icones/Total gerado no dia.png"
                      alt=""
                    />
                    <p>4,5 MWh</p>
                  </div>
                </div>
                <div className={styles.squareWrapper}>
                  <p>Total mensal</p>
                  <div className={styles.square}>
                    <img
                      src="https://prisma.safiraenergia.com.br:3333/images/mapstyles/icones/Total mensal.png"
                      alt=""
                    />
                    <p>42.353 kWh</p>
                  </div>
                </div>
              </div> */}
              <h3>Dados Gerais no horário de Brasília (UTC-3)</h3>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  data={mergedData}
                  margin={{ top: 10, right: 50 }}
                >
                  <CartesianGrid
                    stroke="#0000001c"
                    horizontal={true}
                    vertical={true}
                    strokeDasharray={"10 10"}
                  />

                  <XAxis dataKey="time" tickCount={1} />
                  <YAxis yAxisId={1} width={85}>
                    <Label value="Potência (kW)" angle={-90} offset={100} />
                  </YAxis>
                  <YAxis yAxisId={2} orientation="right" width={85}>
                    <Label value="Irradiância (W/m²)" angle={90} />
                  </YAxis>
                  <Tooltip content={CustomTooltip} />
                  <Legend />
                  <Area
                    type="linear"
                    dataKey="sumPac.sumPac"
                    name="Potência"
                    fill="#E34E8C"
                    stroke="#E34E8C"
                    connectNulls
                    yAxisId={1}
                  />
                  <Area
                    type="linear"
                    stroke="#0A014F"
                    dataKey="irradiance.irradiance"
                    fill="none"
                    name="Irradiância"
                    connectNulls
                    yAxisId={2}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};
