import React from "react";
import { Marker, MapRef } from "react-map-gl";
import { Source, Layer } from "react-map-gl";
import { FeatureCollection, Feature, Point, GeoJsonProperties } from "geojson";
import CemadenTimeRange from "../Navbar/CemadenTimeRange/CemadenTimeRange";
import SearchBarCemaden from "../SearchBar/SearchBarCemaden";
import ColoursIndicatorCemaden from "../ColoursIndicators/ColoursIndicatorCemaden";

const CemadenMarkers = ({
  setCemadenData,
  reference,
  cemadenData,
  popupCemaden,
  setPopupCemaden,
  changePopupCemaden,
  instantCemadenData,
  setInstantCemadenData,
  cemadenDayValue,
  setCemadenDayValue,
  cemadenSelectedButton,
  setCemadenSelectedButton,
  cemadenTwentyFourHours,
}: {
  reference: React.RefObject<MapRef>;
  cemadenData: CemadenDataType[] | undefined;
  popupCemaden: GeoJsonProperties | undefined;
  setPopupCemaden: (entry: GeoJsonProperties | undefined) => void;
  changePopupCemaden: (entry: CemadenDataType | undefined) => void;
  setCemadenData: (entry?: CemadenDataType[] | undefined) => void;
  instantCemadenData: CemadenDataType[] | undefined;
  setInstantCemadenData: (entry: CemadenDataType[] | undefined) => void;
  cemadenDayValue: number;
  setCemadenDayValue: (entry: number) => void;
  cemadenSelectedButton: number | null;
  setCemadenSelectedButton: (entry: number | null) => void;
  cemadenTwentyFourHours: cemadenDateTime[] | undefined;
}) => {
  // Converte JSON para GEOjson
  function convertToGeoJSON(
    data: CemadenDataType[] | undefined
  ): FeatureCollection<Point> {
    const features: Feature<Point>[] = (data ?? []).map((obj) => {
      const feature: Feature<Point> = {
        type: "Feature",
        properties: {
          city: obj.city,
          stationName: obj.stationName,
          accumulated: obj.acumulated,
          stationCode: obj.stationCode,
          ibgeCode: obj.ibgeCode,
          status: obj.status,
        },
        geometry: {
          type: "Point",
          coordinates: [obj.longitude, obj.latitude],
        },
      };

      return feature;
    });

    const geojson: FeatureCollection<Point> = {
      type: "FeatureCollection",
      features: features,
    };

    return geojson;
  }

  //Ordena por valor de maior chuva
  const sortedInstantCemadenData = (instantCemadenData || []).sort(
    (a, b) => a.acumulated - b.acumulated
  );

  return (
    <>
      <ColoursIndicatorCemaden />
      <SearchBarCemaden
        reference={reference}
        cemadenData={cemadenData}
        popupCemaden={popupCemaden}
        setPopupCemaden={setPopupCemaden}
        changePopupCemaden={changePopupCemaden}
      />
      {/* Timerange cemaden */}
      <CemadenTimeRange
        cemadenData={cemadenData}
        instantCemadenData={instantCemadenData}
        setInstantCemadenData={setInstantCemadenData}
        cemadenDayValue={cemadenDayValue}
        setCemadenDayValue={setCemadenDayValue}
        cemadenSelectedButton={cemadenSelectedButton}
        setCemadenSelectedButton={setCemadenSelectedButton}
        cemadenTwentyFourHours={cemadenTwentyFourHours}
      />
      {/* Marcadores Cemaden */}
      <Source
        id="cemaden"
        type="geojson"
        data={convertToGeoJSON(sortedInstantCemadenData)}
      >
        <Layer
          interactive={true}
          id="cemaden"
          type="circle"
          paint={{
            "circle-radius": [
              "case",
              ["==", ["get", "accumulated"], 0], // Se "accumulated" for igual a 0
              5, // Define o raio mínimo do círculo como 5
              15, // Se "accumulated" for diferente de 0, define o raio do círculo como 15
            ],
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "accumulated"],
              0,
              "#f1f1f1",
              1,
              "#e7f6fc",
              5,
              "#81d2fa",
              10,
              "#1777ba",
              15,
              "#032677",
              20,
              "#78be21",
              25,
              "#3ca611",
              30,
              "#008e00",
              40,
              "#2c460c",
              50,
              "#f2c31b",
              70,
              "#ff7700",
              90,
              "#de3400",
              120,
              "#aa0000",
              150,
              "#600000",
              200,
              "#cd7ef5",
              250,
              "#9611d6",
            ],
            "circle-opacity": [
              "case",
              ["==", ["get", "accumulated"], 0], // Se "accumulated" for igual a 0
              0.5, // Opacidade 50% (metade da opacidade) - aplica-se apenas para sombra
              1, // Opacidade padrão (100%)
            ],
            "circle-stroke-width": [
              "case",
              ["==", ["get", "accumulated"], 0], // Se "accumulated" for igual a 0
              1, // Largura da borda em pixels
              0, // Sem borda
            ],
            "circle-stroke-color": "#000000",
            "circle-stroke-opacity": [
              "case",
              ["==", ["get", "accumulated"], 0], // Se "accumulated" for igual a 0
              0.5, // Opacidade 50% (metade da opacidade) - aplica-se apenas para a borda
              1, // Opacidade padrão (100%)
            ],
          }}
        ></Layer>

        <Layer
          id="meu-texto-layer"
          type="symbol"
          layout={{
            "text-field": [
              "case",
              ["!=", ["get", "accumulated"], 0], // Exibe o texto apenas quando "accumulated" for diferente de 0
              ["concat", ["to-string", ["round", ["get", "accumulated"]]], ""],
              "",
            ],
            "text-anchor": "center",
            "text-offset": [0, 0],
            "text-size": 11,
          }}
          paint={{
            "text-opacity": 1,
            "text-color": [
              "interpolate",
              ["linear"],
              ["get", "accumulated"],
              0,
              "black",
              5,
              "black",
              5.1,
              "white",
            ],
          }}
        />
      </Source>
    </>
  );
};

export default CemadenMarkers;
