import React, { useEffect, useState } from "react";
import { MapRef } from "react-map-gl";
import style from "./ChangeStyle.module.css";

const ChangeStyle = ({ mapRef }: { mapRef: React.RefObject<MapRef> }) => {
  const changeStyleButtons = [
    { label: "satellite-streets-v12", value: 1 },
    { label: "outdoors-v12", value: 2 },
    { label: "light-v11", value: 3 },
    { label: "dark-v11", value: 4 },
    { label: "navigation-day-v1", value: 5 },
    { label: "navigation-night-v1", value: 6 },
  ];

  return (
    <div className={style.buttonContainer}>
      {changeStyleButtons.map((entry) => (
        <button
          key={entry.value}
          className={style.changeStyleButton}
          onClick={() => {
            mapRef?.current
              ?.getMap()
              .setStyle("mapbox://styles/mapbox/" + entry.label);
            console.log(mapRef?.current);
          }}
        />
      ))}
    </div>
  );
};

export default ChangeStyle;
