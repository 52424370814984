import react from "react";
import styles from "./ShowShapesButton.module.css";

const ShowShapesButton = ({
  toggleSelectedShapes,
}: {
  toggleSelectedShapes: () => void;
}) => {
  return (
    <div className={styles.selectShapeDiv}>
      <h4>Shapes</h4>
      <div className={styles.shapeCheckbox}>
        <input
          type="checkbox"
          name="sin"
          id="sin"
          onChange={toggleSelectedShapes}
        />
        <label htmlFor="sin" className={styles.labelCheckbox}>
          Bacias Hidrográficas
        </label>
      </div>
    </div>
  );
};

export default ShowShapesButton;
