/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import styles from "../FullScreenButton/FullScreenButton.module.css";
import { BsFullscreen, BsFullscreenExit, BsSearch } from "react-icons/bs";
import { ZoomInOut } from "../ZoomInOut/ZoomInOut";

const FullScreenButton: React.FC = () => {
  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };
  const [fullScreen, setFullscreen] = useState<boolean>(
    !!window.document.fullscreenElement
  );

  useEffect(() => {
    addEventListener("fullscreenchange", (event) => {
      setFullscreen(!!window.document.fullscreenElement);
    });
    return removeEventListener("fullscreenchange", (event) => {
      setFullscreen(!!window.document.fullscreenElement);
    });
  }, []);

  return (
    <button onClick={handleFullScreen} className={styles.FullScreenButton}>
      {fullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
    </button>
  );
};

export default FullScreenButton;
