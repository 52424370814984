import React, { useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  AttributionControl,
  Map,
  MapRef,
  Source,
  Layer,
  LineLayer,
  NavigationControl,
  Popup,
} from "react-map-gl";

import Navbar from "../Navbar/Navbar";
import HydroeletricsMarkers from "../HydroeletricsMarkers/HydroeletricsMarkers";
import axios from "axios";
import HydroeletricsPopup from "../HydroeletricsPopup/HydroeletricsPopup";
import StationMarker from "../StationInmetMarkers/StationInmetMarker";
import StationInmetPopup from "../StationInmetPopup/StationInmetPopup";
import FullScreenButton from "../FullScreenButton/FullScreenButton";
import mapboxgl, { MapMouseEvent, MapboxGeoJSONFeature } from "mapbox-gl";
import InmetReferenceButton from "../InmetReferenceButton/InmetReferenceButton";
import SearchBarHydroeletrics from "../SearchBar/SearchBarHydroeletrics";
import { type } from "os";
import PowerPlantSafira from "../PowerPlantSafira/PowerPlantSafira";
import PowerPlantSafiraPopup from "../PowerPlantSafira/PowerPlantSafiraPopup";
import ChangeStyle from "../ChangeStyle/ChangeStyle";
import CemadenMarkers from "../CemadenMarkers/CemadenMarkers";
import {
  FeatureCollection,
  Feature,
  Point,
  GeoJSON,
  Geometry,
  GeoJsonProperties,
} from "geojson";
import ShowPluviButton from "../Navbar/ShowPluviButton/ShowPluviButton";
import ShowShapesButton from "../Navbar/ShowShapesButton/ShowShapesButton";
import Shapes from "../Shapes/Shapes";
import CemadenPopup from "../CemadenPopUp/CemadenPopup";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "../Redirect/Redirect";
import { SafiraUfv } from "../SafiraUfv/SafiraUfv";
import { useDebounce } from "usehooks-ts";
import { ZoomInOut } from "../ZoomInOut/ZoomInOut";
import { Api } from "services/api";
import { useAuth } from "oidc-react";
import ChileMarkers from "../ChileMarkers/ChileMarkers";
import ChilePlantsPopup from "../ChilePlantsPopup/ChilePlantsPopup";

//coordenadas que limitam o tamanho total do mapa
const sw = new mapboxgl.LngLat(-180, -43);
const ne = new mapboxgl.LngLat(180, 43);
const llb = new mapboxgl.LngLatBounds(sw, ne);

const Routes = () => {
  //States
  const mapRef = useRef<MapRef>(null);
  // mapRef?.current?.getMap().setStyle()
  const [firstLoading, setFirstLoading] = useState(true);
  const [popupData, setPopupData] = useState<
    DataHidroeletricsType | PointerAndPopUp
  >();
  const [showMarkers, setShowMarkers] = useState<DataHidroeletricsType[]>([]);
  const [showStation, setShowStation] = useState<DataStationType[]>([]);
  const [timeRange, setTimeRange] = useState<IntervalTimeRangeType[]>([]);
  const [popupInmetData, setPopupinmetData] = useState<DataStationType>();
  const [popupChileData, setPopupChileData] = useState<ChilePlantsType>();
  const [renderMarkers, setRenderMarkers] = useState(false);
  const [renderInmetMarkers, setRenderInmetMarkers] = useState(false);
  const [renderGfs, setRenderGfs] = useState(false);
  const [renderEcmwf, setRenderEcmwf] = useState(false);
  const [dataRdh, setDataRdh] = useState<DataRdhType[]>();
  const [stationInmetData, setStationInmetData] =
    useState<StationInmetDataType[]>();
  const [hydroeletricDayValue, setHydroeletricDayValue] = useState(6);
  const [stationDayValue, setStationDayValue] = useState(23);
  const [cemadenDayValue, setCemadenDayValue] = useState(24);
  //useDebounce para que o dado de cemadenDayValue tenha seu valor enviado para o backend somente depois de meio segundo biblioteca usehooks-ts
  const cemadenDayValueBounce = useDebounce(cemadenDayValue, 500);
  const [hydroeletricsMarkersData, setHydroeletricsMarkersData] =
    useState<DataRdhType[]>();
  const [stationInmetMarkersData, setStationInmetMarkersData] =
    useState<StationInmetDataType[]>();
  const [selectedHydroeletricsData, setSelectedHydroeletricsData] =
    useState("");
  const [selectedHydroeletricsSubmarket, setSelectedHydroeletricsSubmarket] =
    useState("");
  const [selectHydroeletricsBowl, setSelectedHydroeletricsBowl] = useState("");

  const [selectedStationData, setSelectedStationData] = useState("rain");
  const [accumulatedRainData, setAccumulatedRainData] =
    useState<AccumulatedHourType[]>();
  const [accumulatedSixHour, setAccumulatedSixHour] = useState(5);
  const [twelveAccumulatedRainData, setTwelveAccumulatedRainData] =
    useState<AccumulatedHourType[]>();
  const [twentyFourAccumulatedRainData, setTwentyFourAccumulatedRainData] =
    useState<AccumulatedHourType[]>();
  const [fortyEightAccumulatedRainData, setFortyEightAccumulatedRainData] =
    useState<AccumulatedHourType[]>();
  const [seventyTwoAccumulatedRainData, setSeventyTwoAccumulatedRainData] =
    useState<AccumulatedHourType[]>();
  const [sinShapeData, setSinShapeData] = useState("");
  const [tempMinMaxData, setTempMinMaxData] = useState<TempMinMaxType[]>();
  const [intervalSelectedButton, setIntervalSelectedButton] = useState<
    number | null
  >(1);
  const [cemadenSelectedButton, setCemadenSelectedButton] = useState<
    number | null
  >(1);
  const [showSafiraMarkers, setShowSafiraMarkers] = useState(false);
  const [showSafiraPopUp, setShowSafiraPopUp] = useState(false);
  const [selectedUsina, setSelectedUsina] = useState<PointerAndPopUp>();
  const [safiraUsinasFotovoltaicas, setSafiraUsinasFotovoltaicas] =
    useState("");
  const [safiraUsinasData, setSafiraUsinasData] = useState<
    Array<PointerAndPopUp | Polygons>
  >([]);
  const [markerClicked, setMarkerClicked] = useState<
    PointerAndPopUp | undefined
  >();
  //estado do dado cemaden
  const [cemadenData, setCemadenData] = useState<CemadenDataType[]>();
  //estado que define se o dado pluviometro está selecionado ou não.
  const [selectedPluvi, setSelectedPluvi] = useState(false);
  //estado para o shape selecionado
  const [selectedShapes, setSelectedShapes] = useState(false);
  //estado para pop'up cemaden
  const [popupCemaden, setPopupCemaden] = useState<any | CemadenDataType>(
    undefined
  );
  const [currentDay, setCurrentDay] = useState<currentHourDayType[]>();

  //estado para dados de chuva acumulada do inmet
  const [accumulatedRainInmet, setAccumulatedRainInmet] =
    useState<AccumulatedHourType[]>();

  //estado de dados instantâneos do cemaden
  const [instantCemadenData, setInstantCemadenData] =
    useState<CemadenDataType[]>();
  //estado que puxa os HORARIOS das ultimas 24 horas do cemaden
  const [cemadenTwentyFourHours, setCemadenTwentyFourHours] =
    useState<cemadenDateTime[]>();
  //estado para os dados das usinas do chile
  const [chilePlants, setChilePlants] = useState<ChilePlantsType[]>();
  //estado que renderiza ou não as estações no mapa
  const [renderChileMarkers, setRenderChileMarkers] = useState(false);

  useEffect(() => {
    Api.get("accumulated-rain-inmet")
      .then((response) => setAccumulatedRainInmet(response.data))
      .catch((err) => console.log(err));
  }, []);

  //query que busca os HORARIOS das ultimas 24 horas do cemaden
  useEffect(() => {
    Api.get("instant-hours-cemaden")
      .then((response) => setCemadenTwentyFourHours(response.data))
      .catch((err) => console.log(err));
  }, []);

  //envia o dado de dia/hora para o dado instantâneo dos marcadores cemaden depois só de meio segundo, o value bouce de cemaden day vai estar sendo armazenado somente meio segundo depois de ter seus dados.
  useEffect(() => {
    if (
      cemadenTwentyFourHours &&
      cemadenDayValueBounce >= 0 &&
      cemadenDayValueBounce < cemadenTwentyFourHours.length
    ) {
      Api.get("instant-rain-cemaden", {
        params: {
          date: cemadenTwentyFourHours[cemadenDayValueBounce].dateTime,
        },
      })
        .then((response) => setInstantCemadenData(response.data))
        .catch((err) => console.log(err));
    }
  }, [cemadenTwentyFourHours, cemadenDayValueBounce]);

  useEffect(() => {
    Api.get("station-inmet-hour-day")
      .then((response) => {
        setCurrentDay(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // const userInfos = useAuth();
  // const userEmailDomain = userInfos.userData?.profile.email?.match(/(.+)/)?.[0];

  // useEffect(() => {
  //   if (
  //     userEmailDomain === "@gpsafira.com.br" ||
  //     userEmailDomain === "@safiraenergia.com.br"
  //   ) {
  //     fetch(
  //       "https://prisma.safiraenergia.com.br:3333/images/UsinasFotovoltaicasSafira.geojson"
  //     )
  //       .then((resp) => resp.json())
  //       .then((json) => {
  //         setSafiraUsinasFotovoltaicas(json);
  //         setSafiraUsinasData(json.features);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [userEmailDomain]);

  const userInfos = useAuth();
  const userEmailDomain =
    userInfos.userData?.profile.email?.match(/(@.+)/)?.[0];

  //se caso o usuário for @gpsafira ou @safiraenergia os dados das estações são buscados, do contrário não.
  useEffect(() => {
    if (
      userEmailDomain === "@gpsafira.com.br" ||
      userEmailDomain === "@safiraenergia.com.br"
    ) {
      fetch(
        "https://prisma.safiraenergia.com.br:3333/images/UsinasFotovoltaicasSafira.geojson"
      )
        .then((resp) => resp.json())
        .then((json) => {
          setSafiraUsinasFotovoltaicas(json);
          setSafiraUsinasData(json.features);
        })
        .catch((err) => console.log(err));
    }
  }, [userEmailDomain]);

  //pesquisa das bacias
  useEffect(() => {
    fetch(
      "https://prisma.safiraenergia.com.br:3333/images/SIN_transparent_sem_bacias_1.geojson"
    )
      .then((resp) => resp.json())
      .then((json) => setSinShapeData(json))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get(`stations-inmet-markers-data`)
      .then((response) => setStationInmetMarkersData(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get(`hydroeletrics-markers-data`)
      .then((response) => setHydroeletricsMarkersData(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get(`hydroeletric-plants`)
      .then((response) => setShowMarkers(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get(`info-station-inmet`)
      .then((response) => setShowStation(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!popupData) return;
    Api.get("data-rdh", {
      params: {
        station:
          "station" in popupData
            ? popupData.station
            : popupData.properties.name,
      },
    })
      .then((response) => setDataRdh(response.data))
      .catch((err) => console.log(err));
  }, [popupData]);

  useEffect(() => {
    if (!popupInmetData) return;
    Api.get("station-inmet", {
      params: {
        cdStation: popupInmetData.cdStation,
      },
    })
      .then((response) => setStationInmetData(response.data))
      .catch((err) => console.log(err));
  }, [popupInmetData]);

  // useEffect(() => {
  //   Api.get(`six-hour-accumulated-rain`)
  //     .then((response) => setAccumulatedRainData(response.data))
  //     .catch((err) => console.log(err));
  // }, []);
  // useEffect(() => {
  //   Api.get(`twelve-hours-accumulated-rain`)
  //     .then((response) => setTwelveAccumulatedRainData(response.data))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   Api.get(`twenty-four-accumulated-rain`)
  //     .then((response) => setTwentyFourAccumulatedRainData(response.data))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   Api.get(`forty-eight-accumulated-rain`)
  //     .then((response) => setFortyEightAccumulatedRainData(response.data))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   Api.get(`seventy-two-accumulated-rain`)
  //     .then((response) => setSeventyTwoAccumulatedRainData(response.data))
  //     .catch((err) => console.log(err));
  // }, []);
  useEffect(() => {
    Api.get(`temp-min-max`)
      .then((response) => setTempMinMaxData(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get("cemaden")
      .then((response) => setCemadenData(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Api.get(`days-interval-time-range-hydroeletrics`)
      .then((response) => setTimeRange(response.data))
      .catch((err) => console.log(err));
  }, []);

  //rota que busca os dados das estações do chile
  useEffect(() => {
    Api.get(`chile-plants`)
      .then((response) => setChilePlants(response.data))
      .catch((err) => console.log(err));
  }, []);

  //Change and Toggles
  function changePopup(entry?: DataHidroeletricsType | PointerAndPopUp) {
    setPopupData(entry);
  }

  function changePopupInmet(entry?: DataStationType) {
    setPopupinmetData(entry);
  }

  function changePopupChile(entry?: ChilePlantsType) {
    setPopupChileData(entry);
  }

  function changePopupCemaden(entry?: CemadenDataType | undefined) {
    if (entry) {
      setPopupCemaden(entry);
    }
  }

  function selectHydroeletricsDataToggle(entry: string) {
    setSelectedHydroeletricsData(entry);
  }

  function selectHydroeletricsBowlToggle(entry: string) {
    setSelectedHydroeletricsBowl(entry);
  }

  function selectHydroeletricsSubmarketToggle(entry: string) {
    setSelectedHydroeletricsSubmarket(entry);
  }

  function selectStationDataToggle(entry: string) {
    setSelectedStationData(entry);
  }

  const toggleMarkers = () => {
    setRenderMarkers((state) => !state);
    setRenderInmetMarkers(false);
    setSelectedPluvi(false);
    setRenderChileMarkers(false);
  };

  const toggleInmetMarkers = () => {
    setRenderInmetMarkers((state) => !state);
    setRenderMarkers(false);
    setSelectedPluvi(false);
    setRenderChileMarkers(false);
  };

  const toggleChileMarkers = () => {
    setRenderChileMarkers((state) => !state);
    setRenderMarkers(false);
    setSelectedPluvi(false);
    setRenderInmetMarkers(false);
  };

  //função que troca o estado dos marcadores da safira, se serão ou não exibidos.
  const toggleSafiraMarkers = () => {
    setShowSafiraMarkers((state) => !state);
  };

  //função que troca o estado dos marcadores cemaden
  const toggleCemadenMarkers = () => {
    setRenderMarkers(false);
    setRenderInmetMarkers(false);
    setSelectedPluvi((state) => !state);
    setRenderChileMarkers(false);
  };

  //função que troca o estado dos marcadores de usinas do chile

  const toggleSelectedShapes = () => {
    setSelectedShapes((state) => !state);
  };

  const toggleGfs = () => setRenderGfs((state) => !state);

  const toggleEcmwf = () => setRenderEcmwf((state) => !state);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstLoading(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    //o operador ...(spread operator) vai pegar e fornecer as propriedades da autenticação para todo os filhos dentro dele
    <>
      {/* componente de mensagem de boas vindas */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        style={{ fontSize: "12px", fontWeight: "bold" }}
      />
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Map
          ref={mapRef}
          mapboxAccessToken="pk.eyJ1IjoibGt1cmF0YW5pIiwiYSI6ImNrbGdta3ZoeDEyOWQyb282eTRtMHR0bGsifQ.zUtO-VuPkNJzr20q3qAVaA"
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: "-moz-initial",
          }}
          initialViewState={{
            longitude: -61.1,
            latitude: -15.7,
            zoom: 3,
          }}
          mapStyle="mapbox://styles/mapbox/outdoors-v12"
          attributionControl={false}
          projection={"equirectangular"}
          //Desabilita a função que o botão direito tem de girar o mapa
          dragRotate={false}
          //define o zoom minimo em 3 para que a layer do mapa não fique vazada
          minZoom={3}
          //define a limitação que o mapa vai exibir
          maxBounds={llb}
          onClick={(event: any) => {
            //aqui estamos dizendo que ao clicar no mapa, se caso clicarmos em um marcador cemaden, vamos mudar os valores do pop'up do cemaden
            const cemadenMarker = mapRef.current?.queryRenderedFeatures(
              event.point,
              {
                layers: ["cemaden"],
              }
            )[0];

            //se este valor existir, vamos definir o pop'up.
            if (cemadenMarker) {
              setPopupCemaden(cemadenMarker);
              let latitude = (cemadenMarker as any).geometry.coordinates[1];
              let longitude = (cemadenMarker as any).geometry.coordinates[0];
              mapRef.current?.jumpTo({ center: [longitude, latitude] });
            }
          }}
        >
          <AttributionControl customAttribution="SafiraOn" />
          {/* Renderiza o Pop'up dos postos e os marcadores */}
          {/* Fiz com que asism que o mapa seja iniciado, fique 7 segundos carregando para que os dados dos dias dos marcadores hidroelétricos possam ser carregados a tempo. */}{" "}
          {/* redirecionamento para o safira on */}
          <Redirect />
          {/* Botão de tela cheia */}
          <FullScreenButton />
          {/* Componente com os botões zoom in e out */}
          <ZoomInOut />
          {/* Componente de mudar estilo do mapa */}
          <ChangeStyle mapRef={mapRef} />
          {/* botão de redirecionamento ao inmet */}
          <InmetReferenceButton />
          {(() => {
            if (!renderMarkers) return null;

            return (
              <>
                <HydroeletricsMarkers
                  setPopupData={changePopup}
                  stationList={showMarkers}
                  changeDayValue={setHydroeletricDayValue}
                  hydroeletricDayValue={hydroeletricDayValue}
                  rdhData={dataRdh}
                  hydroeletricsMarkersData={hydroeletricsMarkersData}
                  selectedHydroeletricsData={selectedHydroeletricsData}
                  mapRef={mapRef}
                  showMarkers={showMarkers}
                  changePopupInmet={changePopupInmet}
                  safiraUsinasData={safiraUsinasData}
                  showSafiraMarkers={showSafiraMarkers}
                  timeRange={timeRange}
                  selectedHydroeletricsSubmarket={
                    selectedHydroeletricsSubmarket
                  }
                  selectHydroeletricsBowl={selectHydroeletricsBowl}
                />
                <HydroeletricsPopup
                  changePopup={changePopup}
                  hydroeletricsData={popupData}
                  rdhData={dataRdh}
                  dayValue={hydroeletricDayValue}
                />
              </>
            );
          })()}
          {/* Renderiza marcadores das estações */}
          {(() => {
            //verifica se os dados devem ser mostrados ou não
            if (!renderInmetMarkers) return null;

            //verifica se todos os dados estão carregados

            //verificação de outros dados da condição de carregamento removida temporariamente:
            // ||
            //   !accumulatedRainData ||
            //   !twelveAccumulatedRainData ||
            //   !twentyFourAccumulatedRainData ||
            //   !fortyEightAccumulatedRainData ||
            //   !seventyTwoAccumulatedRainData

            if (!stationInmetMarkersData) {
              return <LoadingScreen />;
            }
            return (
              <>
                <StationMarker
                  stationList={showStation}
                  setPopupinmetData={setPopupinmetData}
                  stationDayValue={stationDayValue}
                  setStationDayValue={setStationDayValue}
                  selectedStationData={selectedStationData}
                  stationInmetMarkersData={stationInmetMarkersData}
                  accumulatedRainData={accumulatedRainData}
                  twelveAccumulatedRainData={twelveAccumulatedRainData}
                  twentyFourAccumulatedRainData={twentyFourAccumulatedRainData}
                  fortyEightAccumulatedRainData={fortyEightAccumulatedRainData}
                  seventyTwoAccumulatedRainData={seventyTwoAccumulatedRainData}
                  tempMinMaxData={tempMinMaxData}
                  intervalSelectedButton={intervalSelectedButton}
                  setIntervalSelectedButton={setIntervalSelectedButton}
                  reference={mapRef}
                  popupInmetData={popupInmetData}
                  changePopupInmet={changePopupInmet}
                  currentDay={currentDay}
                  setCurrentDay={setCurrentDay}
                  accumulatedSixHour={accumulatedSixHour}
                  setAccumulatedSixHour={setAccumulatedSixHour}
                  accumulatedRainInmet={accumulatedRainInmet}
                  setAccumulatedRainInmet={setAccumulatedRainInmet}
                />
                ;
                <StationInmetPopup
                  entry={popupInmetData}
                  changePopupInmet={changePopupInmet}
                  stationInmetData={stationInmetData}
                  setStationInmetData={setStationInmetData}
                  stationDayValue={stationDayValue}
                  setStationDayValue={setStationDayValue}
                />
              </>
            );
          })()}
          {/* marcadores cemaden */}
          {(() => {
            if (!cemadenData && selectedPluvi) {
              return <LoadingScreen />;
            }

            if (cemadenData && selectedPluvi)
              return (
                <>
                  <CemadenMarkers
                    reference={mapRef}
                    cemadenData={cemadenData}
                    popupCemaden={popupCemaden}
                    setPopupCemaden={setPopupCemaden}
                    changePopupCemaden={changePopupCemaden}
                    setCemadenData={setCemadenData}
                    instantCemadenData={instantCemadenData}
                    setInstantCemadenData={setInstantCemadenData}
                    cemadenDayValue={cemadenDayValue}
                    setCemadenDayValue={setCemadenDayValue}
                    cemadenSelectedButton={cemadenSelectedButton}
                    setCemadenSelectedButton={setCemadenSelectedButton}
                    cemadenTwentyFourHours={cemadenTwentyFourHours}
                  />
                </>
              );
          })()}
          {/* popup cemaden */}
          {popupCemaden && (
            <CemadenPopup
              popupCemaden={popupCemaden}
              setPopupCemaden={setPopupCemaden}
            />
          )}
          {/* GFS */}
          {/* {(() => {
              if (!renderGfs) return null;
  
              return (
                <>
                  <Source
                    type="image"
                    url="https://api-safira-on-prisma.safiraenergia.com.br/testePentadaGFS.png"
                    coordinates={imgCoordinates}
                  >
                    <Layer type="raster" />
                  </Source>
                </>
              );
            })()} */}
          {/* <Source
              type="image"
              url="https://api-safira-on-prisma.safiraenergia.com.br/testePentadaGFS.png"
              coordinates={imgCoordinates}
            >
              <Layer type="raster" />
            </Source> */}
          <Shapes sinShapeData={sinShapeData} selectedShapes={selectedShapes} />
          {(() => {
            if (!showSafiraMarkers) return null;

            if (!safiraUsinasData && showSafiraMarkers) {
              return <LoadingScreen />;
            }

            return (
              <>
                <PowerPlantSafira
                  showSafiraMarkers={showSafiraMarkers}
                  selectedUsina={selectedUsina}
                  setSelectedUsina={setSelectedUsina}
                  safiraUsinasFotovoltaicas={safiraUsinasFotovoltaicas}
                  setSafiraUsinasFotovoltaicas={setSafiraUsinasFotovoltaicas}
                  safiraUsinasData={safiraUsinasData}
                  setSafiraUsinasData={setSafiraUsinasData}
                  setMarkerClicked={setMarkerClicked}
                  toggleSafiraMarkers={toggleSafiraMarkers}
                  setShowSafiraPopUp={setShowSafiraPopUp}
                />

                <PowerPlantSafiraPopup
                  markerClicked={markerClicked}
                  setMarkerClicked={setMarkerClicked}
                  showSafiraMarkers={showSafiraMarkers}
                />
              </>
            );
          })()}
          {(() => {
            if (!markerClicked) return null;

            return (
              <SafiraUfv
                markerClicked={markerClicked}
                setMarkerClicked={setMarkerClicked}
              />
            );
          })()}
          {/* Ecmwf */}
          {/* {(() => {
              if (!renderEcmwf) return null;
  
              return (
                <>
                  <Source
                    type="image"
                    url="https://api-safira-on-prisma.safiraenergia.com.br/testePentadaECMWF2.png"
                    coordinates={imgCoordinates}
                  >
                    <Layer type="raster" />
                  </Source>
                </>
              );
            })()} */}
          {/* Marcadores do Chile */}
          {(() => {
            if (!renderChileMarkers) return null;

            if (!chilePlants && renderChileMarkers) {
              return <LoadingScreen />;
            }

            return (
              <>
                <ChileMarkers
                  chilePlants={chilePlants}
                  setPopupChileData={setPopupChileData}
                  reference={mapRef}
                />
                <ChilePlantsPopup
                  chilePlants={chilePlants}
                  changePopupChile={changePopupChile}
                  popupChileData={popupChileData}
                />
              </>
            );
          })()}
          <Navbar
            stationData={showMarkers}
            toggleMarkers={toggleMarkers}
            toggleInmetMarkers={toggleInmetMarkers}
            toggleGfs={toggleGfs}
            toggleEcmwf={toggleEcmwf}
            setPopupData={setPopupData}
            reference={mapRef}
            showMarkers={showMarkers}
            changePopupInmet={changePopupInmet}
            dataHidroeletrics={[]}
            setShowStation={function (
              entry?: DataStationType | undefined
            ): void {
              throw new Error("Function not implemented.");
            }}
            changePopup={function (
              entry?: DataHidroeletricsType | undefined
            ): void {
              throw new Error("Function not implemented.");
            }}
            selectedHydroeletricsData={selectedHydroeletricsData}
            selectHydroeletricsDataToggle={selectHydroeletricsDataToggle}
            selectedStationData={selectedStationData}
            selectStationDataToggle={selectStationDataToggle}
            renderMarkers={renderMarkers}
            renderInmetMarkers={renderInmetMarkers}
            setIntervalSelectedButton={setIntervalSelectedButton}
            toggleSafiraMarkers={toggleSafiraMarkers}
            toggleCemadenMarkers={toggleCemadenMarkers}
            selectedPluvi={selectedPluvi}
            selectedShapes={selectedShapes}
            toggleSelectedShapes={toggleSelectedShapes}
            instantCemadenData={instantCemadenData}
            setInstantCemadenData={setInstantCemadenData}
            currentDay={currentDay}
            setCurrentDay={setCurrentDay}
            setStationDayValue={setStationDayValue}
            setAccumulatedRainData={setAccumulatedRainData}
            accumulatedRainData={accumulatedRainData}
            setAccumulatedSixHour={setAccumulatedSixHour}
            toggleChileMarkers={toggleChileMarkers}
            renderChileMarkers={renderChileMarkers}
            selectHydroeletricsBowlToggle={selectHydroeletricsBowlToggle}
            selectHydroeletricsSubmarketToggle={
              selectHydroeletricsSubmarketToggle
            }
            selectedHydroeletricsSubmarket={selectedHydroeletricsSubmarket}
            selectHydroeletricsBowl={selectHydroeletricsBowl}
          />
        </Map>
      </div>
      {/* primeira tela de carregamento */}
      {firstLoading ? <LoadingScreen /> : null}
    </>
  );
};

export default Routes;
