import styles from "../PowerPlantSafira/PowerPlantSafira.module.css";
import { Popup } from "react-map-gl";
import { SafiraUfv } from "../SafiraUfv/SafiraUfv";

const PowerPlantSafiraPopup = ({
  markerClicked,
  setMarkerClicked,
  showSafiraMarkers,
}: {
  markerClicked: PointerAndPopUp | undefined;
  setMarkerClicked: (entry: PointerAndPopUp | undefined) => void;
  showSafiraMarkers: boolean;
}) => {
  //verifica se é diferente de montes claros, porque essa por hora é a unica que não vai mostrar dados
  if (markerClicked && markerClicked.properties.name !== "UFV Montes Claros")
    return (
      <>
        <Popup
          //nunca esquecer que sempre que usar o popUp devem ser utilizadas as props ONCLOSE.
          longitude={markerClicked?.geometry.coordinates[0] ?? 0}
          latitude={markerClicked?.geometry.coordinates[1] ?? 0}
          closeButton={false}
          onClose={() => setMarkerClicked(undefined)}
          className={styles.popUpSafira}
          style={{ borderRadius: "5px" }}
          closeOnMove={true}
          anchor={"bottom"}
        >
          <h4>{markerClicked?.properties.name}</h4>
        </Popup>
      </>
    );

  return null;
};

export default PowerPlantSafiraPopup;
