import { useState, useEffect } from "react";
import { Marker, Layer, Source } from "react-map-gl";
import styles from "./StationInmetMarker.module.css";
import StationTimeRange from "../Navbar/StationTimeRange/StationTimeRange";
import SearchBarHydroeletrics from "../SearchBar/SearchBarHydroeletrics";
import { MapRef } from "react-map-gl";
import SearchBarStation from "../SearchBar/SearchBarStation";
import ColoursIndicatorInmet from "../ColoursIndicators/ColoursIndicatorInmet";

export type StationMarkerDataType = [
  {
    cdStation: string;
    hrMeasurement: string;
    dtMeasurement: string;
    wingDiretion: number;
    rain: number;
    preIns: number;
    umdMax: number;
    preMin: number;
    preMax: number;
    venVel: number;
    ptoMin: number;
    temMax: number;
    radGlo: number;
    ptoIns: number;
    venRaj: number;
    temIns: number;
    umdIns: number;
    temMin: number;
    umdMin: number;
    ptoMax: number;
  }
];

const StationMarker = ({
  stationList,
  setPopupinmetData,
  stationDayValue,
  setStationDayValue,
  selectedStationData,
  stationInmetMarkersData,
  accumulatedRainData,
  twelveAccumulatedRainData,
  twentyFourAccumulatedRainData,
  fortyEightAccumulatedRainData,
  seventyTwoAccumulatedRainData,
  tempMinMaxData,
  intervalSelectedButton,
  setIntervalSelectedButton,
  reference,
  popupInmetData,
  changePopupInmet,
  currentDay,
  setCurrentDay,
  accumulatedSixHour,
  setAccumulatedSixHour,
  accumulatedRainInmet,
  setAccumulatedRainInmet,
}: {
  //   setShowStation: (entry?: DataStation) => void;
  stationList: DataStationType[];
  setPopupinmetData: (entry?: DataStationType) => void;
  stationDayValue: number;
  setStationDayValue: (entry: number) => void;
  stationInmetMarkersData: StationInmetDataType[] | undefined;
  selectedStationData: string;
  accumulatedRainData: AccumulatedHourType[] | undefined;
  twelveAccumulatedRainData: AccumulatedHourType[] | undefined;
  twentyFourAccumulatedRainData: AccumulatedHourType[] | undefined;
  fortyEightAccumulatedRainData: AccumulatedHourType[] | undefined;
  seventyTwoAccumulatedRainData: AccumulatedHourType[] | undefined;
  tempMinMaxData: TempMinMaxType[] | undefined;
  intervalSelectedButton: number | null;
  setIntervalSelectedButton: (entry: number | null) => void;
  reference: React.RefObject<MapRef>;
  popupInmetData: DataStationType | undefined;
  changePopupInmet: (entry?: DataStationType) => void;
  currentDay: currentHourDayType[] | undefined;
  setCurrentDay: (entry: currentHourDayType[] | undefined) => void;
  accumulatedSixHour: number;
  setAccumulatedSixHour: (entry: number) => void;
  accumulatedRainInmet: AccumulatedHourType[] | undefined;
  setAccumulatedRainInmet: (entry: AccumulatedHourType[] | undefined) => void;
}) => {
  const [accumulatedTwelveHour, setAccumulatedTwelveHour] = useState(2);
  const [accumulatedTwentyHour, setAccumulatedTwentyHour] = useState(4);
  const [accumulatedFortyEight, setAccumulatedFortyEight] = useState(2);
  const [accumulatedSeventyTwo, setAccumulatedSeventyTwo] = useState(2);

  const selectInterval = (buttonId: number) => {
    if (buttonId === intervalSelectedButton) {
      setIntervalSelectedButton(buttonId);
    } else {
      setIntervalSelectedButton(buttonId);
    }
  };

  // Função para preencher os dados ausentes de uma estação
  function fillMissingData(
    stationData: AccumulatedHourType[] | undefined
  ): AccumulatedHourType[] {
    if (!stationData) {
      return [];
    }

    const filledData: AccumulatedHourType[] = [];
    for (let i = 0; i < 6; i++) {
      const dataIndex = Math.min(i, stationData.length - 1);
      const autoCompleted = i >= stationData.length; // Verifica se foi auto-completado
      filledData.push({
        ...stationData[dataIndex],
        autoCompleted, // Adiciona a propriedade autoCompleted
      });
    }
    return filledData;
  }

  // Cria um novo array com os dados faltantes das estações preenchidos
  const filledAccumulatedRainInmet: AccumulatedHourType[] | undefined =
    accumulatedRainInmet
      ? accumulatedRainInmet
          .reduce<AccumulatedHourType[][]>((accumulator, currentData) => {
            if (
              accumulator.length === 0 ||
              accumulator[accumulator.length - 1][0]?.cdStation !==
                currentData.cdStation
            ) {
              accumulator.push([]);
            }
            accumulator[accumulator.length - 1].push(currentData);
            return accumulator;
          }, [])
          .map(fillMissingData)
          .reduce<AccumulatedHourType[]>(
            (accumulator, currentData) => accumulator.concat(currentData),
            []
          )
      : undefined;

  return (
    <>
      {/* Indicador de cores */}
      <ColoursIndicatorInmet selectedStationData={selectedStationData} />

      {/* Linha de tempo para as estações */}
      <StationTimeRange
        selectedStationData={selectedStationData}
        stationDayValue={stationDayValue}
        setStationDayValue={setStationDayValue}
        accumulatedRainData={accumulatedRainData}
        intervalSelectedButton={intervalSelectedButton}
        selectInterval={selectInterval}
        setAccumulatedSixHour={setAccumulatedSixHour}
        accumulatedSixHour={accumulatedSixHour}
        twelveAccumulatedRainData={twelveAccumulatedRainData}
        accumulatedTwelveHour={accumulatedTwelveHour}
        setAccumulatedTwelveHour={setAccumulatedTwelveHour}
        twentyFourAccumulatedRainData={twentyFourAccumulatedRainData}
        accumulatedTwentyHour={accumulatedTwentyHour}
        setAccumulatedTwentyHour={setAccumulatedTwentyHour}
        fortyEightAccumulatedRainData={fortyEightAccumulatedRainData}
        accumulatedFortyEight={accumulatedFortyEight}
        setAccumulatedFortyEight={setAccumulatedFortyEight}
        seventyTwoAccumulatedRainData={seventyTwoAccumulatedRainData}
        accumulatedSeventyTwo={accumulatedSeventyTwo}
        setAccumulatedSeventyTwo={setAccumulatedSeventyTwo}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
        accumulatedRainInmet={accumulatedRainInmet}
        setAccumulatedRainInmet={setAccumulatedRainInmet}
      />
      {/* Barra de pesquisa para as estações */}
      <SearchBarStation
        reference={reference}
        stationList={stationList}
        setPopupInmetData={setPopupinmetData}
        popupInmetData={popupInmetData}
        changePopupInmet={changePopupInmet}
      />

      {(() => {
        //Marcadores de chuva instantânea
        if (selectedStationData === "rain" && intervalSelectedButton === 1) {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <button className={styles.buttonStationMarkers}>
                    {(() => {
                      const dataValueArray = stationInmetMarkersData?.filter(
                        (data) => {
                          return data.cdStation === entry.cdStation;
                        }
                      );
                      const dataValue = dataValueArray?.[stationDayValue]?.rain;

                      let background = "#ffffff";
                      let color = "black";

                      if (!dataValue && dataValue !== 0) return null;

                      if (dataValue === 0) {
                        return (
                          <div
                            className={styles.zeroRain}
                            style={{ background, color }}
                          >
                            <p>{null}</p>
                          </div>
                        );
                      }

                      if (dataValue === 0) {
                        background = "#f1f1f1";
                      } else if (dataValue > 0 && dataValue <= 1) {
                        background = "#e7f6fc";
                      } else if (dataValue > 1 && dataValue <= 5) {
                        background = "#81d2fa";
                      } else if (dataValue > 5 && dataValue <= 10) {
                        background = "#1777ba";
                        color = "white";
                      } else if (dataValue > 10 && dataValue <= 15) {
                        background = "#032677";
                        color = "white";
                      } else if (dataValue > 15 && dataValue <= 20) {
                        background = "#78be21";
                        color = "black";
                      } else if (dataValue > 20 && dataValue <= 25) {
                        background = "#3ca611";
                        color = "black";
                      } else if (dataValue > 25 && dataValue <= 30) {
                        background = "#008e00";
                        color = "black";
                      } else if (dataValue > 30 && dataValue <= 40) {
                        background = "#2c460c";
                        color = "white";
                      } else if (dataValue > 40 && dataValue <= 50) {
                        background = "#f2c31b";
                        color = "black";
                      } else if (dataValue > 50 && dataValue <= 70) {
                        background = "#ff7700";
                        color = "black";
                      } else if (dataValue > 70 && dataValue <= 90) {
                        background = "#de3400";
                        color = "black";
                      } else if (dataValue > 90 && dataValue <= 120) {
                        background = "#aa0000";
                        color = "white";
                      } else if (dataValue > 120 && dataValue <= 150) {
                        background = "#600000";
                        color = "white";
                      } else if (dataValue > 150 && dataValue <= 200) {
                        background = "#cd7ef5";
                        color = "black";
                      } else if (dataValue > 200 && dataValue <= 250) {
                        background = "#9611d6";
                        color = "white";
                      }

                      return (
                        <div
                          className={styles.rain}
                          style={{ background, color }}
                        >
                          <p>
                            {dataValue
                              .toLocaleString("pt-BR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                              .replace(".", ",")}
                          </p>
                        </div>
                      );
                    })()}
                  </button>
                </Marker>
              ))}
            </div>
          );
        }

        //Marcadores de chuva acumulada das 72h
        if (selectedStationData === "rain" && intervalSelectedButton === 2) {
          if (filledAccumulatedRainInmet) {
            const mergedStationList: MergedStationType[] = stationList
              .map((dataStation) => {
                const accumulatedData = filledAccumulatedRainInmet.filter(
                  (accumulated) =>
                    accumulated.cdStation === dataStation.cdStation
                );

                const rainSum = accumulatedData[accumulatedSixHour]?.rainSum;

                return {
                  ...dataStation,
                  rainSum,
                };
              })
              .filter((value) => value.rainSum !== undefined);

            mergedStationList.sort((a, b) => a.rainSum - b.rainSum);

            return mergedStationList.map((entry) => (
              <Marker
                key={entry.cdStation}
                longitude={entry.vlLongitude}
                latitude={entry.vlLatitude}
                onClick={(event) => {
                  event.originalEvent.stopPropagation();
                  setPopupinmetData(entry);
                }}
              >
                <button className={styles.buttonStationMarkers}>
                  {(() => {
                    //Marcadores de chuva acumulada 6h
                    const dataValue = entry.rainSum || 0;

                    let background = "#ffffff";
                    let color = "black";
                    let border = "";

                    if (!dataValue && dataValue !== 0) return null;

                    if (dataValue === 0) {
                      return (
                        <div
                          className={styles.zeroRain}
                          style={{ background, color }}
                        >
                          <p>{null}</p>
                        </div>
                      );
                    }

                    // Verifica se a propriedade autoCompleted está presente no objeto de dados
                    const isAutoCompleted = filledAccumulatedRainInmet?.filter(
                      (data) => {
                        return data.cdStation === entry.cdStation;
                      }
                    )[accumulatedSixHour]?.autoCompleted;

                    // Se isAutoCompleted for verdadeiro, aplique uma borda vermelha
                    if (isAutoCompleted) {
                      border = "1px solid red";
                    }

                    if (dataValue > 0 && dataValue <= 1) {
                      background = "#e7f6fc";
                    } else if (dataValue > 1 && dataValue <= 5) {
                      background = "#81d2fa";
                    } else if (dataValue > 5 && dataValue <= 10) {
                      background = "#1777ba";
                      color = "white";
                    } else if (dataValue > 10 && dataValue <= 15) {
                      background = "#032677";
                      color = "white";
                    } else if (dataValue > 15 && dataValue <= 20) {
                      background = "#78be21";
                      color = "black";
                    } else if (dataValue > 20 && dataValue <= 25) {
                      background = "#3ca611";
                      color = "black";
                    } else if (dataValue > 25 && dataValue <= 30) {
                      background = "#008e00";
                      color = "black";
                    } else if (dataValue > 30 && dataValue <= 40) {
                      background = "#2c460c";
                      color = "white";
                    } else if (dataValue > 40 && dataValue <= 50) {
                      background = "#f2c31b";
                      color = "black";
                    } else if (dataValue > 50 && dataValue <= 70) {
                      background = "#ff7700";
                      color = "black";
                    } else if (dataValue > 70 && dataValue <= 90) {
                      background = "#de3400";
                      color = "black";
                    } else if (dataValue > 90 && dataValue <= 120) {
                      background = "#aa0000";
                      color = "white";
                    } else if (dataValue > 120 && dataValue <= 150) {
                      background = "#600000";
                      color = "white";
                    } else if (dataValue > 150 && dataValue <= 200) {
                      background = "#cd7ef5";
                      color = "black";
                    } else if (dataValue > 200 && dataValue <= 250) {
                      background = "#9611d6";
                      color = "white";
                    }

                    return (
                      <div
                        className={styles.rain}
                        style={{ background, color, border }}
                      >
                        <p>
                          {dataValue
                            .toLocaleString("pt-BR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                            .replace(".", ",")}
                        </p>
                      </div>
                    );
                  })()}
                </button>
              </Marker>
            ));
          }
        }

        //Marcadores de temperatura minima instantânea das ultimas 24h
        if (selectedStationData === "tmpmin" && intervalSelectedButton === 1) {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <button className={styles.buttonStationMarkers}>
                    {(() => {
                      const dataValueArray = stationInmetMarkersData?.filter(
                        (data) => {
                          return data.cdStation === entry.cdStation;
                        }
                      );
                      const dataValue =
                        dataValueArray?.[stationDayValue]?.temMin;

                      let background = "#ffffbf";
                      let color = "black";

                      if (!dataValue && dataValue !== 0) return null;
                      if (dataValue === 0) {
                        return (
                          <div
                            className={styles.zeroRain}
                            style={{ background, color }}
                          >
                            <p>{null}</p>
                          </div>
                        );
                      }

                      if (dataValue > 15 && dataValue <= 21) {
                        background = "#e6f598";
                      }
                      if (dataValue > 9 && dataValue <= 15) {
                        background = "#abdda4";
                      }
                      if (dataValue > 3 && dataValue <= 9) {
                        background = "#66c2a5";
                      }
                      if (dataValue > 2 && dataValue <= 3) {
                        background = "#3288bd";
                      }
                      if (dataValue > -8 && dataValue <= 2) {
                        background = "#1f5678";
                        color = "white";
                      }
                      if (dataValue < -8) {
                        background = "#5e4fa2";
                        color = "white";
                      }

                      return (
                        <div
                          className={styles.tmpMin}
                          style={{ background, color }}
                        >
                          <p>
                            {dataValue
                              .toLocaleString("pt-BR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                              .replace(".", ",")}
                          </p>
                        </div>
                      );
                    })()}
                  </button>
                </Marker>
              ))}
            </div>
          );
        }

        //Marcadores de temperatura minima das ultimas 24h
        if (selectedStationData === "tmpmin" && intervalSelectedButton === 2) {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <button className={styles.buttonStationMarkers}>
                    {(() => {
                      const dataValue = tempMinMaxData?.find((data) => {
                        return data.cdStation === entry.cdStation;
                      })?.tmpMin;

                      let background = "#ffffbf";
                      let color = "black";

                      if (!dataValue && dataValue !== 0) return null;
                      if (dataValue === 0) {
                        return (
                          <div
                            className={styles.zeroRain}
                            style={{ background, color }}
                          >
                            <p>{null}</p>
                          </div>
                        );
                      }

                      if (dataValue > 15 && dataValue <= 21) {
                        background = "#e6f598";
                      }
                      if (dataValue > 9 && dataValue <= 15) {
                        background = "#abdda4";
                      }
                      if (dataValue > 3 && dataValue <= 9) {
                        background = "#66c2a5";
                      }
                      if (dataValue > 2 && dataValue <= 3) {
                        background = "#3288bd";
                      }
                      if (dataValue > -8 && dataValue <= 2) {
                        background = "#1f5678";
                        color = "white";
                      }
                      if (dataValue < -8) {
                        background = "#5e4fa2";
                        color = "white";
                      }

                      return (
                        <div
                          className={styles.tmpMin}
                          style={{ background, color }}
                        >
                          <p>
                            {dataValue
                              .toLocaleString("pt-BR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                              .replace(".", ",")}
                          </p>
                        </div>
                      );
                    })()}
                  </button>
                </Marker>
              ))}
            </div>
          );
        }

        //Marcadores de temperatura máxima instantânea das ultimas 24h
        if (selectedStationData === "tmpmax" && intervalSelectedButton === 1) {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <button className={styles.buttonStationMarkers}>
                    {(() => {
                      const dataValueArray = stationInmetMarkersData?.filter(
                        (data) => {
                          return data.cdStation === entry.cdStation;
                        }
                      );
                      const dataValue =
                        dataValueArray?.[stationDayValue]?.temMax;

                      let background = "#ffffbf";
                      let color = "black";

                      if (!dataValue && dataValue !== 0) return null;
                      if (dataValue === 0) {
                        return (
                          <div
                            className={styles.zeroRain}
                            style={{ background, color }}
                          >
                            <p>{null}</p>
                          </div>
                        );
                      }

                      if (!dataValue) return null;

                      if (dataValue > 21 && dataValue <= 25) {
                        background = "#fee08b";
                      }
                      if (dataValue > 25 && dataValue <= 29) {
                        background = "#fdae61";
                      }
                      if (dataValue > 29 && dataValue <= 33) {
                        background = "#f46d43";
                      }
                      if (dataValue > 33 && dataValue <= 37) {
                        background = "#d53e4f";
                      }
                      if (dataValue > 37 && dataValue <= 40) {
                        background = "#6d002d";
                        color = "white";
                      }
                      if (dataValue > 40) {
                        background = "#46011e";
                        color = "white";
                      }

                      return (
                        <div
                          className={styles.tmpMax}
                          style={{ background, color }}
                        >
                          <p>
                            {dataValue
                              .toLocaleString("pt-BR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                              .replace(".", ",")}
                          </p>
                        </div>
                      );
                    })()}
                  </button>
                </Marker>
              ))}
            </div>
          );
        }

        //Marcadores de temperatura máxima das ultimas 24h
        if (selectedStationData === "tmpmax" && intervalSelectedButton === 2) {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <button className={styles.buttonStationMarkers}>
                    {(() => {
                      const dataValue = tempMinMaxData?.find((data) => {
                        return data.cdStation === entry.cdStation;
                      })?.tmpMax;

                      let background = "#ffffbf";
                      let color = "black";

                      if (!dataValue && dataValue !== 0) return null;
                      if (dataValue === 0) {
                        return (
                          <div
                            className={styles.zeroRain}
                            style={{ background, color }}
                          >
                            <p>{null}</p>
                          </div>
                        );
                      }

                      if (!dataValue) return null;

                      if (dataValue > 21 && dataValue <= 25) {
                        background = "#fee08b";
                      }
                      if (dataValue > 25 && dataValue <= 29) {
                        background = "#fdae61";
                      }
                      if (dataValue > 29 && dataValue <= 33) {
                        background = "#f46d43";
                      }
                      if (dataValue > 33 && dataValue <= 37) {
                        background = "#d53e4f";
                      }
                      if (dataValue > 37 && dataValue <= 40) {
                        background = "#6d002d";
                        color = "white";
                      }
                      if (dataValue > 40) {
                        background = "#46011e";
                        color = "white";
                      }

                      return (
                        <div
                          className={styles.tmpMax}
                          style={{ background, color }}
                        >
                          <p>
                            {dataValue
                              .toLocaleString("pt-BR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                              .replace(".", ",")}
                          </p>
                        </div>
                      );
                    })()}
                  </button>
                </Marker>
              ))}
            </div>
          );
        }

        //Marcadores sem dados.
        if (selectedStationData === "") {
          return (
            <div>
              {stationList.map((entry) => (
                <Marker
                  key={entry.cdStation}
                  longitude={entry.vlLongitude}
                  latitude={entry.vlLatitude}
                  onClick={(event) => {
                    event.originalEvent.stopPropagation();
                    setPopupinmetData(entry);
                  }}
                >
                  <img
                    className={styles.stationImage}
                    src="https://api-safira-on-prisma.safiraenergia.com.br/estacao_meteorologica.png"
                    alt=""
                    style={{
                      width: "30px",
                    }}
                  />
                </Marker>
              ))}
            </div>
          );
        }
      })()}
    </>
  );
};

export default StationMarker;
