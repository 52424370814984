import styles from "./DescriptionChile.module.css";

const DescriptionChile = () => {
  return (
    <div className={styles.descriptionChile}>
      <div>
        <p>Hidráulica Embalse</p>
        <p>Hidráulica Pasada</p>
        <p>Mini Hidráulica Pasada</p>
      </div>
      <div className={styles.simbolsChile}>
        <span className={styles.redTriangle}></span>
        <span className={styles.blueBall}></span>
        <span className={styles.whiteBall}></span>
      </div>
    </div>
  );
};

export default DescriptionChile;
