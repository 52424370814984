import react from "react";
import styles from "./ColoursIndicator.module.css";
import { BsFillCloudRainFill } from "react-icons/bs";

const rainColors = [
  { label: "0", value: 0, background: "#f1f1f1", color: "black" },
  { label: "0-1", value: 1, background: "#e7f6fc", color: "black" },
  { label: "1-5 ", value: 5, background: "#81d2fa", color: "black" },
  { label: "5-10", value: 10, background: "#1777ba", color: "white" },
  { label: "10-15", value: 15, background: "#032677", color: "white" },
  { label: "15-20", value: 20, background: "#78be21", color: "white" },
  { label: "20-25", value: 25, background: "#3ca611", color: "white" },
  { label: "25-30", value: 30, background: "#008e00", color: "white" },
  { label: "30-40", value: 40, background: "#2c460c", color: "white" },
  { label: "40-50", value: 50, background: "#f2c31b", color: "white" },
  { label: "50-70", value: 70, background: "#ff7700", color: "white" },
  { label: "70-90", value: 90, background: "#de3400", color: "white" },
  { label: "90-120", value: 120, background: "#aa0000", color: "white" },
  { label: "120-150", value: 150, background: "#600000", color: "white" },
  { label: "150-200", value: 200, background: "#cd7ef5", color: "white" },
  { label: ">200mm", value: 250, background: "#9611d6", color: "white" },
];

const ColoursIndicatorCemaden = () => {
  return (
    <>
      <div className={styles.ColoursIndicatorDiv}>
        <div>
          <BsFillCloudRainFill
            style={{
              width: "100%",
              height: "20px",
              marginTop: "10px",
              color: "#1777ba",
            }}
          />
          <p
            className={styles.indicatorPrecipitation}
            style={{ fontSize: "15px", top: "50%" }}
          >
            Precipitação (mm)
          </p>
        </div>
        <div>
          {rainColors.map((division) => (
            <p
              className={styles.MilimetersValue}
              style={{
                background: division.background,
                color: division.color,
              }}
            >
              {division.label}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default ColoursIndicatorCemaden;
