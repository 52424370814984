import { render } from "@testing-library/react";
import React from "react";
import styles from "./ShowHidroeletricsButton.module.css";
import { useAuth } from "oidc-react";

type ShowHidroeletricsButtonType = {
  value: string;
  toggleMarkers: () => void;
  selectedHydroeletricsData: string;
  selectHydroeletricsDataToggle: (entry: string) => void;
  renderMarkers: boolean;
  toggleSafiraMarkers: () => void;
  selectHydroeletricsBowlToggle: (entry: string) => void;
  selectHydroeletricsSubmarketToggle: (entry: string) => void;
  selectedHydroeletricsSubmarket: string;
  selectHydroeletricsBowl: string;
};

const ShowHidroeletricsButton = ({
  toggleMarkers,
  value,
  selectedHydroeletricsData,
  selectHydroeletricsDataToggle,
  renderMarkers,
  toggleSafiraMarkers,
  selectHydroeletricsBowlToggle,
  selectHydroeletricsSubmarketToggle,
  selectedHydroeletricsSubmarket,
  selectHydroeletricsBowl,
}: ShowHidroeletricsButtonType) => {
  let backgroundColor = "#032677";
  let color = "#ffffff";

  if (renderMarkers === true) {
    backgroundColor = "#bee6ff";
    color = "#032677";
  }

  const userInfos = useAuth();
  const userEmailDomain =
    userInfos.userData?.profile.email?.match(/@(.+)/)?.[0];

  const bowlOptions = [
    { label: "Amazonas", value: 1, subsystem: "N" },
    { label: "Araguari", value: 2, subsystem: "N" },
    { label: "Capivari", value: 3, subsystem: "S" },
    { label: "Doce", value: 4, subsystem: "SE" },
    { label: "Grande", value: 5, subsystem: "SE" },
    { label: "Iguaçu", value: 6, subsystem: "S" },
    { label: "Itabapoana", value: 7, subsystem: "SE" },
    { label: "Itajaí", value: 8, subsystem: "S" },
    { label: "Jacuí", value: 9, subsystem: "S" },
    { label: "Jequitinhonha (Nordeste)", value: 10, subsystem: "NE" },
    {
      label: "Jequitinhonha (Sudeste/Centro-Oeste)",
      value: 11,
      subsystem: "SE",
    },
    { label: "Madeira", value: 12, subsystem: "SE" },
    { label: "Mucuri", value: 13, subsystem: "SE" },
    { label: "Paraguai", value: 14, subsystem: "SE" },
    { label: "Paraguaçu", value: 15, subsystem: "NE" },
    { label: "Paranapanema", value: 16, subsystem: "SE" },
    { label: "Paranapanema", value: 28, subsystem: "S" },
    { label: "Paranaíba", value: 17, subsystem: "SE" },
    { label: "Paranaíba", value: 29, subsystem: "NE" },
    { label: "Paraná", value: 18, subsystem: "SE" },
    { label: "Paraíba do Sul", value: 19, subsystem: "SE" },
    { label: "São Francisco (Nordeste)", value: 20, subsystem: "NE" },
    {
      label: "São Francisco (Sudeste/Centro-Oeste)",
      value: 21,
      subsystem: "SE",
    },
    { label: "Teles Pires", value: 22, subsystem: "SE" },
    { label: "Tietê", value: 23, subsystem: "SE" },
    { label: "Tocantins (Norte)", value: 24, subsystem: "N" },
    { label: "Tocantins (Sudeste/Centro-Oeste)", value: 25, subsystem: "SE" },
    { label: "Uruguai", value: 26, subsystem: "S" },
    { label: "Xingu", value: 27, subsystem: "N" },
  ];

  return (
    <>
      <button
        type="button"
        onClick={() => {
          toggleMarkers();
          selectHydroeletricsBowlToggle("all");
          selectHydroeletricsSubmarketToggle("all");
        }}
        className={styles.showHidroeletricsButton}
        style={{ backgroundColor, color }}
      >
        Usinas Hidroelétricas
      </button>
      {/* Selecionar submercado */}
      <div className={styles.descriptionText}>
        <p>Escolha um submercado:</p>
        <select
          name="submarketOptions"
          id="submarketOptions"
          defaultValue={"all"}
          onChange={(event) => {
            selectHydroeletricsSubmarketToggle(event.target.value);
            selectHydroeletricsBowlToggle("all");
          }}
          className={styles.selectedData}
          style={{ width: "100%" }}
          value={selectedHydroeletricsSubmarket}
        >
          <option value="all" style={{ color: "grey" }}>
            Todos
          </option>
          <option value="SE">Sudeste/Centro-Oeste</option>
          <option value="S">Sul</option>
          <option value="NE">Nordeste</option>
          <option value="N">Norte</option>
        </select>
      </div>
      {/* Selecionar bacia */}
      <div className={styles.descriptionText}>
        <p>Escolha uma bacia:</p>
        <select
          name="bowlOptions"
          id="bowlOptions"
          defaultValue={"all"}
          onChange={(event) => {
            selectHydroeletricsBowlToggle(event.target.value);
          }}
          className={styles.selectedData}
          style={{ width: "100%" }}
          value={selectHydroeletricsBowl}
        >
          <option value={"all"} style={{ width: "10%" }}>
            Todos
          </option>
          {bowlOptions
            .filter((entry) => {
              if (selectedHydroeletricsSubmarket === "all") {
                return true; // Mostra todas as bacias se todos os submercados forem selecionados
              } else {
                return entry.subsystem === selectedHydroeletricsSubmarket; // Filtra bacias com base no submercado selecionado
              }
            })
            .map((entry) => (
              <option
                key={entry.value}
                value={entry.label}
                style={{ width: "10%" }}
              >
                {entry.label}
              </option>
            ))}
        </select>
      </div>
      {/* Selecionar dados */}
      <div className={styles.descriptionText}>
        <p>Escolha um dado:</p>
        <select
          name="hidroeletricOption"
          id="hidroeletricOption"
          defaultValue={""}
          onChange={(event) => {
            selectHydroeletricsDataToggle(event.target.value);
          }}
          className={styles.selectedData}
        >
          <option value="" style={{ color: "grey" }}>
            Nenhum
          </option>
          <option value="vzn">Vazão Natural Afluente (m³/s)</option>
          <option value="vzd">Vazão Defluente (m³/s)</option>
          <option value="vzt">Vazão Turbinada (m³/s)</option>
          <option value="vzv">Vazão Vertida (m³/s)</option>
          <option value="vlm">Volume do reservatório (%v.u)</option>
          <option value="nvl">Nivel / Cota (metros)</option>
        </select>
      </div>

      {(() => {
        if (!userEmailDomain) return null;

        if (
          userEmailDomain === "@gpsafira.com.br" ||
          userEmailDomain === "@safiraenergia.com.br"
        ) {
          return (
            <div className={styles.usinaSafiraCheckbox}>
              <input
                type="checkbox"
                id="checkSafira"
                onChange={toggleSafiraMarkers}
              />
              <label>Usinas Fotovoltaicas Safira</label>
            </div>
          );
        }
      })()}
    </>
  );
};

export default ShowHidroeletricsButton;
