import { join } from "path";
import React, { useEffect, useState } from "react";
import { Popup, Map, MapRef } from "react-map-gl";

type CustomPopupProps = {
  hydroeletricsData?: DataHidroeletricsType | PointerAndPopUp | Polygons;
  changePopup: (entry?: DataHidroeletricsType) => void;
  rdhData?: DataRdhType[];
  dayValue: number;
};

function handleMarket(value: string) {
  if (value === "N") {
    return "Norte";
  } else if (value === "S") {
    return "Sul";
  } else if (value === "SE") {
    return "Sudeste/Centro Oeste";
  } else if (value === "NE") {
    return "Nordeste";
  }
}

const HydroeletricsPopup = ({
  hydroeletricsData,
  changePopup,
  rdhData,
  dayValue,
}: CustomPopupProps) => {
  //verifica se existe uma data e formata a mesma
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const correct = new Date(date.getTime() + 86400000);
    return correct.toLocaleDateString("pt-br");
  }

  function verifyDate() {
    if (rdhData?.[dayValue].date !== undefined) {
      return formatDate(rdhData?.[dayValue].date);
    }
  }

  if (!rdhData?.[dayValue]?.date) {
    if (hydroeletricsData && "station_name" in hydroeletricsData) {
      return (
        <>
          {hydroeletricsData && (
            <Popup
              longitude={hydroeletricsData.longitude}
              latitude={hydroeletricsData.latitude}
              onClose={() => changePopup(undefined)}
              style={{ borderRadius: "5px" }}
              closeButton={false}
              anchor={"bottom"}
              closeOnMove={true}
              key={hydroeletricsData.station}
            >
              <>
                <h4>{hydroeletricsData.station_name}</h4>
              </>
            </Popup>
          )}
        </>
      );
    }
    if (hydroeletricsData && "properties" in hydroeletricsData) {
      return (
        <>
          {hydroeletricsData && (
            <Popup
              longitude={hydroeletricsData.geometry.coordinates[0]}
              latitude={hydroeletricsData.geometry.coordinates[1]}
              onClose={() => changePopup(undefined)}
              style={{ borderRadius: "5px" }}
              closeButton={false}
              anchor={"bottom"}
              closeOnMove={true}
              key={hydroeletricsData.properties.name}
            >
              <>
                <h4>{hydroeletricsData.properties.name}</h4>
              </>
            </Popup>
          )}
        </>
      );
    }
    return null;
  } else {
    if (hydroeletricsData && "station_name" in hydroeletricsData) {
      return (
        <>
          {hydroeletricsData && (
            <Popup
              longitude={hydroeletricsData.longitude}
              latitude={hydroeletricsData.latitude}
              onClose={() => changePopup(undefined)}
              style={{ borderRadius: "5px" }}
              closeOnMove={true}
              closeButton={false}
              anchor={"bottom"}
              key={hydroeletricsData.station}
            >
              <h4>{hydroeletricsData.station_name}</h4>
              {/* <p>Data: {verifyDate()}</p> */}
              <p>Código: {hydroeletricsData.station}</p>
              <p>Submercado: {handleMarket(hydroeletricsData.subsystem)}</p>
              <p>Proprietário: {hydroeletricsData.owner}</p>
              <p>Bacia: {hydroeletricsData.bowl}</p>
              {/* abaixo o código mostra os dados do RDH */}
              {/* <p>Bacia: {rdhData?.[dayValue].bowl}</p> */}
              {/*
              <p>Vazão Natural: {rdhData?.[dayValue].naturalFlow} (m³/s)</p>
              <p>Vazão Defluente: {rdhData?.[dayValue].effluentFlow} (m³/s)</p>
              <p>Volume: {rdhData?.[dayValue].storedVolume} (%v.u.)</p>
              <p>Nivel: {rdhData?.[dayValue].level} (metros)</p> */}
            </Popup>
          )}
        </>
      );
    }
    if (hydroeletricsData && "properties" in hydroeletricsData) {
      return (
        <>
          {hydroeletricsData && (
            <Popup
              longitude={hydroeletricsData.geometry.coordinates[0]}
              latitude={hydroeletricsData.geometry.coordinates[1]}
              onClose={() => changePopup(undefined)}
              style={{ borderRadius: "5px" }}
              closeOnMove={true}
              closeButton={false}
              anchor={"bottom"}
              key={hydroeletricsData.properties.name}
            >
              <h4>{hydroeletricsData.properties.name}</h4>
            </Popup>
          )}
        </>
      );
    }

    return null;
  }
};
export default HydroeletricsPopup;
