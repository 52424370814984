import styles from "../InmetReferenceButton/InmetReferenceButton.module.css";

const InmetReferenceButton: React.FC = () => {
  return (
    <a
      href="https://portal.inmet.gov.br/"
      target="_blank"
      className={styles.InmetReferenceButton}
      rel="noreferrer"
    >
      <img
        className={styles.imgInmet}
        src="https://prisma.safiraenergia.com.br:3333/images/InmetLogoReference.jpeg"
        alt=""
      />
    </a>
  );
};

export default InmetReferenceButton;
