import React from "react";
import { Marker, MapRef } from "react-map-gl";
import styles from "./ChileMarkers.module.css";
import SearchBarChilePlants from "../SearchBar/SearchBarChilePlants";
import DescriptionChile from "../DescriptionChile/DescriptionChile";

const ChileMarkers = ({
  chilePlants,
  setPopupChileData,
  reference,
}: {
  chilePlants: ChilePlantsType[] | undefined;
  setPopupChileData: (entry?: ChilePlantsType) => void;
  reference: React.RefObject<MapRef>;
}) => {
  return (
    <>
      <SearchBarChilePlants
        setPopupChileData={setPopupChileData}
        chilePlants={chilePlants}
        reference={reference}
      />
      <DescriptionChile />;
      {chilePlants?.map((entry) => {
        if (entry.font === "Hidráulica Embalse") {
          return (
            <Marker
              key={entry.id}
              longitude={entry.longitude}
              latitude={entry.latitude}
              onClick={(event) => {
                event.originalEvent.stopPropagation();
                setPopupChileData(entry);
              }}
            >
              <div className={styles.triangleMarker}></div>
            </Marker>
          );
        }
        if (entry.font === "Mini Hidráulica Pasada") {
          return (
            <Marker
              key={entry.id}
              longitude={entry.longitude}
              latitude={entry.latitude}
              onClick={(event) => {
                event.originalEvent.stopPropagation();
                setPopupChileData(entry);
              }}
            >
              <div className={styles.circleMiniMarker}></div>
            </Marker>
          );
        }

        if (entry.font === "Hidráulica Pasada") {
          return (
            <Marker
              key={entry.id}
              longitude={entry.longitude}
              latitude={entry.latitude}
              onClick={(event) => {
                event.originalEvent.stopPropagation();
                setPopupChileData(entry);
              }}
            >
              <div className={styles.circleBigMarker}></div>
            </Marker>
          );
        }

        return null;
      })}
    </>
  );
};

export default ChileMarkers;
