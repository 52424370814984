import react, { useState } from "react";
import styles from "./ColoursIndicator.module.css";
import { BsFillCloudRainFill } from "react-icons/bs";
import { FaTemperatureLow, FaTemperatureHigh } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const rainColors = [
  { label: "0", value: 0, background: "#f1f1f1", color: "black" },
  { label: "0-1", value: 1, background: "#e7f6fc", color: "black" },
  { label: "1-5", value: 5, background: "#81d2fa", color: "black" },
  { label: "5-10", value: 10, background: "#1777ba", color: "white" },
  { label: "10-15", value: 15, background: "#032677", color: "white" },
  { label: "15-20", value: 20, background: "#78be21", color: "black" },
  { label: "20-25", value: 25, background: "#3ca611", color: "black" },
  { label: "25-30", value: 30, background: "#008e00", color: "black" },
  { label: "30-40", value: 40, background: "#2c460c", color: "white" },
  { label: "40-50", value: 50, background: "#f2c31b", color: "black" },
  { label: "50-70", value: 70, background: "#ff7700", color: "black" },
  { label: "70-90", value: 90, background: "#de3400", color: "black" },
  { label: "90-120", value: 120, background: "#aa0000", color: "white" },
  { label: "120-150", value: 150, background: "#600000", color: "white" },
  { label: "150-200", value: 200, background: "#cd7ef5", color: "black" },
  { label: ">200mm", value: 250, background: "#9611d6", color: "white" },
];

const tempMinColors = [
  { label: "21°", value: 1, background: "#ffffbf", color: "black" },
  { label: "21° - 15°", value: 2, background: "#e6f598", color: "black" },
  { label: "15° - 9°", value: 3, background: "#abdda4", color: "black" },
  { label: "9° - 3°", value: 4, background: "#66c2a5", color: "black" },
  { label: "3° - 2°", value: 5, background: "#3288bd", color: "black" },
  { label: "2° - -8°", value: 6, background: "#1f5678", color: "white" },
  { label: "< -8°", value: 7, background: "#5e4fa2", color: "white" },
];

const tempMaxColors = [
  { label: "21°", value: 1, background: "#ffffbf", color: "black" },
  { label: "21° - 25°", value: 2, background: "#fee08b", color: "black" },
  { label: "25° - 29°", value: 3, background: "#fdae61", color: "black" },
  { label: "29° - 33°", value: 4, background: "#f46d43", color: "black" },
  { label: "33° - 37°", value: 5, background: "#d53e4f", color: "black" },
  { label: "37° - 40°", value: 6, background: "#6d002d", color: "white" },
  { label: "> 40°", value: 7, background: "#46011e", color: "white" },
];

const ColoursIndicatorInmet = ({
  selectedStationData,
}: {
  selectedStationData: string;
}) => {
  const [disclaimerClose, setDisclaimerClose] = useState(true);

  if (selectedStationData === "rain") {
    return (
      <>
        <div className={styles.ColoursIndicatorDiv}>
          <div>
            <BsFillCloudRainFill
              style={{
                width: "100%",
                height: "20px",
                marginTop: "10px",
                color: "#1777ba",
              }}
            />
            <p
              className={styles.indicatorPrecipitation}
              style={{ fontSize: "15px", top: "50%" }}
            >
              Precipitação (mm)
            </p>
          </div>
          <div>
            {rainColors.map((division) => (
              <p
                className={styles.MilimetersValue}
                style={{
                  background: division.background,
                  color: division.color,
                }}
              >
                {division.label}
              </p>
            ))}
          </div>
        </div>

        {(() => {
          if (disclaimerClose) {
            return (
              <div className={styles.rainDisclaimer}>
                <div className={styles.attemptHeader}>
                  <div>
                    <FiAlertTriangle
                      style={{
                        width: "100%",
                        height: "20px",
                        marginTop: "5px",
                        color: "#F2C31B",
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                  <h3>Atenção</h3>
                  <div />
                  <div>
                    <button
                      className={styles.disclaimerCloseButton}
                      style={{
                        color: "black",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setDisclaimerClose(false);
                        console.log("teste");
                      }}
                    >
                      <IoMdClose />
                    </button>
                  </div>
                </div>
                <div>
                  <p>
                    Estações marcadas com bordas vermelhas indicam que houve
                    alguma ausência de dados ou falha de registro em algum
                    momento das últimas 72 horas.
                  </p>
                </div>
              </div>
            );
          }
        })()}
      </>
    );
  }
  if (selectedStationData === "tmpmin") {
    return (
      <>
        <div className={styles.ColoursIndicatorDiv}>
          <div>
            <FaTemperatureLow
              style={{
                width: "100%",
                height: "20px",
                marginTop: "10px",
                color: "#5e4fa2",
              }}
            />
            <p
              className={styles.indicatorPrecipitation}
              style={{ fontSize: "15px", top: "50%" }}
            >
              Temp Min C°
            </p>
          </div>
          <div>
            {tempMinColors.map((division) => (
              <p
                className={styles.MilimetersValue}
                style={{
                  background: division.background,
                  color: division.color,
                }}
              >
                {division.label}
              </p>
            ))}
          </div>
        </div>
      </>
    );
  }
  if (selectedStationData === "tmpmax") {
    return (
      <>
        <div className={styles.ColoursIndicatorDiv}>
          <div>
            <FaTemperatureHigh
              style={{
                width: "100%",
                height: "20px",
                marginTop: "10px",
                color: "#d53e4f",
              }}
            />
            <p
              className={styles.indicatorPrecipitation}
              style={{ fontSize: "15px", top: "50%" }}
            >
              Temp Max C°
            </p>
          </div>
          <div>
            {tempMaxColors.map((division) => (
              <p
                className={styles.MilimetersValue}
                style={{
                  background: division.background,
                  color: division.color,
                }}
              >
                {division.label}
              </p>
            ))}
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default ColoursIndicatorInmet;
