import React, { useEffect, useState } from "react";
import styles from "./StationTimeRange.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ColoursIndicatorCemaden from "../../ColoursIndicators/ColoursIndicatorCemaden";

// import TimeInterval from "./TimeInterval/TimeInterval";

const StationTimeRange = ({
  stationDayValue,
  setStationDayValue,
  selectedStationData,
  accumulatedRainData,
  intervalSelectedButton,
  selectInterval,
  setAccumulatedSixHour,
  accumulatedSixHour,
  twelveAccumulatedRainData,
  accumulatedTwelveHour,
  setAccumulatedTwelveHour,
  twentyFourAccumulatedRainData,
  accumulatedTwentyHour,
  setAccumulatedTwentyHour,
  fortyEightAccumulatedRainData,
  accumulatedFortyEight,
  setAccumulatedFortyEight,
  seventyTwoAccumulatedRainData,
  accumulatedSeventyTwo,
  setAccumulatedSeventyTwo,
  currentDay,
  setCurrentDay,
  accumulatedRainInmet,
  setAccumulatedRainInmet,
}: {
  stationDayValue: number;
  accumulatedSixHour: number;
  setStationDayValue: (entry: number) => void;
  selectedStationData: string;
  accumulatedRainData: AccumulatedHourType[] | undefined;
  intervalSelectedButton: number | null;
  selectInterval: (buttonId: number) => void;
  setAccumulatedSixHour: (entry: number) => void;
  twelveAccumulatedRainData: AccumulatedHourType[] | undefined;
  accumulatedTwelveHour: number;
  setAccumulatedTwelveHour: (entry: number) => void;
  twentyFourAccumulatedRainData: AccumulatedHourType[] | undefined;
  accumulatedTwentyHour: number;
  setAccumulatedTwentyHour: (entry: number) => void;
  fortyEightAccumulatedRainData: AccumulatedHourType[] | undefined;
  accumulatedFortyEight: number;
  setAccumulatedFortyEight: (entry: number) => void;
  seventyTwoAccumulatedRainData: AccumulatedHourType[] | undefined;
  accumulatedSeventyTwo: number;
  setAccumulatedSeventyTwo: (entry: number) => void;
  currentDay: currentHourDayType[] | undefined;
  setCurrentDay: (entry: currentHourDayType[] | undefined) => void;
  accumulatedRainInmet: AccumulatedHourType[] | undefined;
  setAccumulatedRainInmet: (entry: AccumulatedHourType[] | undefined) => void;
}) => {
  const rainButtons = [
    { label: "Inst", value: 1 },
    { label: "Acum", value: 2 },
    // { label: "12h", value: 3 },
    // { label: "24h", value: 4 },
    // { label: "48h", value: 5 },
    // { label: "72h", value: 6 },
  ];

  const tmpMinButtons = [
    { label: "Inst", value: 1 },
    { label: "Min", value: 2 },
  ];

  const tmpMaxButtons = [
    { label: "Inst", value: 1 },
    { label: "Max ", value: 2 },
  ];

  //função para formatar data
  function formatDate(dateString?: string) {
    if (!dateString) return null;

    const date = new Date(dateString);
    const correct = new Date(date.getTime() + 86400000);
    return correct.toLocaleDateString("pt-br");
  }

  //função para formatar horario
  function hourRound(hour: string) {
    const hourNumber = parseInt(hour);

    if (hourNumber === 0) {
      return "00";
    }
    if (hourNumber === 100) {
      return "01";
    }
    if (hourNumber === 200) {
      return "02";
    }
    if (hourNumber === 300) {
      return "03";
    }
    if (hourNumber === 400) {
      return "04";
    }
    if (hourNumber === 500) {
      return "05";
    }
    if (hourNumber === 600) {
      return "06";
    }
    if (hourNumber === 700) {
      return "07";
    }
    if (hourNumber === 800) {
      return "08";
    }
    if (hourNumber === 900) {
      return "09";
    }
    if (hourNumber === 1000) {
      return "10";
    }
    if (hourNumber === 1100) {
      return "11";
    }
    if (hourNumber === 1200) {
      return "12";
    }
    if (hourNumber === 1300) {
      return "13";
    }
    if (hourNumber === 1400) {
      return "14";
    }
    if (hourNumber === 1500) {
      return "15";
    }
    if (hourNumber === 1600) {
      return "16";
    }
    if (hourNumber === 1700) {
      return "17";
    }
    if (hourNumber === 1800) {
      return "18";
    }
    if (hourNumber === 1900) {
      return "19";
    }
    if (hourNumber === 2000) {
      return "20";
    }
    if (hourNumber === 2100) {
      return "21";
    }
    if (hourNumber === 2200) {
      return "22";
    }
    if (hourNumber === 2300) {
      return "23";
    } else {
      return "NaN";
    }
  }

  //função que exibe o respectivo dia pra cada dado
  const dayValue = () => {
    if (selectedStationData === "rain" && intervalSelectedButton === 1) {
      return (
        formatDate(`${currentDay?.[stationDayValue]?.dtMeasurement}`) +
        " às " +
        hourRound(`${currentDay?.[stationDayValue]?.hrMeasurement}`)
      );
    }
    if (selectedStationData === "rain" && intervalSelectedButton === 2) {
      return (
        formatDate(accumulatedRainInmet?.[accumulatedSixHour].dtMeasurement) +
        " às " +
        hourRound(
          `${accumulatedRainInmet?.[accumulatedSixHour]?.hrMeasurement}`
        )
      );
    }
    if (selectedStationData === "rain" && intervalSelectedButton === 3) {
      return (
        formatDate(
          twelveAccumulatedRainData?.[accumulatedTwelveHour].dtMeasurement
        ) +
        " às " +
        hourRound(
          `${twelveAccumulatedRainData?.[accumulatedTwelveHour].hrMeasurement}`
        )
      );
    }
    if (selectedStationData === "rain" && intervalSelectedButton === 4) {
      const days = twentyFourAccumulatedRainData?.filter(
        (station) => station.cdStation === "A001"
      );

      return (
        formatDate(days?.[accumulatedTwentyHour].dtMeasurement) +
        " às " +
        hourRound(`${days?.[accumulatedTwentyHour].hrMeasurement}`)
      );
    }
    if (selectedStationData === "rain" && intervalSelectedButton === 5) {
      const days = fortyEightAccumulatedRainData?.filter(
        (station) => station.cdStation === "A001"
      );

      return (
        formatDate(days?.[accumulatedFortyEight].dtMeasurement) +
        " às " +
        hourRound(`${days?.[accumulatedFortyEight].hrMeasurement}`)
      );
    }
    if (selectedStationData === "rain" && intervalSelectedButton === 6) {
      const days = seventyTwoAccumulatedRainData?.filter(
        (station) => station.cdStation === "A001"
      );

      return (
        formatDate(days?.[accumulatedSeventyTwo].dtMeasurement) +
        " às " +
        hourRound(`${days?.[accumulatedSeventyTwo].hrMeasurement}`)
      );
    }
    if (selectedStationData === "tmpmin" && intervalSelectedButton === 1) {
      if (currentDay) {
        return (
          formatDate(currentDay?.[stationDayValue]?.dtMeasurement) +
          " às " +
          `${hourRound(currentDay?.[stationDayValue].hrMeasurement)}h UTC`
        );
      }
    }
    if (selectedStationData === "tmpmax" && intervalSelectedButton === 1) {
      if (currentDay) {
        return (
          formatDate(currentDay?.[stationDayValue]?.dtMeasurement) +
          " às " +
          `${hourRound(currentDay?.[stationDayValue].hrMeasurement)}h UTC`
        );
      }
    }
    return null;
  };

  const [descriptionHour, setDescriptionHour] = useState(5);
  const [formatedDescriptionHour, setFormatedDescriptionHour] = useState("72h");
  function updateFormattedDescription(hourValue: number) {
    switch (hourValue) {
      case 5:
        return "72h";
      case 4:
        return "48h";
      case 3:
        return "24h";
      case 2:
        return "12h";
      case 1:
        return "6h";
      case 0:
        return "";
      default:
        return "";
    }
  }

  //solução para timeline que não ficava na posição correta quando alterado qual dado deveria ser exibido
  //estou com dois states que sempre que o state intervalSelectedButton é alterado, o slider é recarregado e corrige o tempo que foi escolhido anteriormente
  const [componentKey1, setComponentKey1] = useState(0);
  const [componentKey2, setComponentKey2] = useState(0);

  useEffect(() => {
    setComponentKey1((prevKey) => prevKey + 1);
  }, [intervalSelectedButton]);

  useEffect(() => {
    setComponentKey2((prevKey) => prevKey + 1);
  }, [intervalSelectedButton]);

  // Chuva Acumulada Instantânea
  if (selectedStationData === "rain" && intervalSelectedButton === 1) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>
          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>

        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677", marginBottom: "6px" }}>
              Dia: {dayValue()}h UTC
            </h2>
          </label>
          <p>Precipitação instantânea</p>
          <p style={{ fontWeight: "bold" }}>Escolha o horário:</p>
          <Slider
            key={componentKey1}
            defaultValue={stationDayValue}
            style={{ fontSize: "200px" }}
            min={0}
            max={currentDay && currentDay?.length - 1}
            step={1}
            marks={Object.fromEntries(
              currentDay?.map((value, index) => [
                index,
                {
                  label: hourRound(value.hrMeasurement) + "h",
                },
              ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              setStationDayValue(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{
              backgroundColor: "#578B18",
              padding: "3px",
            }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
        {/* <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  //chuva acumulada
  if (selectedStationData === "rain" && intervalSelectedButton === 2) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>

          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677", marginBottom: "6px" }}>
              Dia: {dayValue()}h UTC
            </h2>
          </label>
          <p>
            Precipitação acumulada nas últimas {formatedDescriptionHour} horas.
          </p>
          <p style={{ fontWeight: "bold" }}>Escolha o horário:</p>
          <Slider
            key={componentKey2}
            defaultValue={accumulatedSixHour}
            min={0}
            max={5}
            step={1}
            reverse
            // Object.fromEntries(
            //   accumulatedRainInmet?.map((value, index) => [
            //     index,
            //     {
            //       label: hourRound(value.hrMeasurement) + "h",
            //     },
            //   ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            // )
            marks={{
              0: <p>Atual</p>,
              1: <p>6h</p>,
              2: <p>12h</p>,
              3: <p>24h</p>,
              4: <p>48h</p>,
              5: <p>72h</p>,
            }}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              setAccumulatedSixHour(value);
              setDescriptionHour(value);
              const formattedDesc = updateFormattedDescription(value);
              setFormatedDescriptionHour(formattedDesc);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
            style={{ width: "100%" }}
          />
        </div>
        {/* <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  if (selectedStationData === "rain" && intervalSelectedButton === 3) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>
          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677" }}>Dia: {dayValue()}h</h2>
          </label>
          <br />
          <p>Escolha o horário:</p>
          <Slider
            min={0}
            max={2}
            step={1}
            marks={Object.fromEntries(
              twelveAccumulatedRainData?.map((value, index) => [
                index,
                {
                  label: hourRound(value.hrMeasurement) + "h",
                },
              ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={0}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              setAccumulatedTwelveHour(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>

        {/* <TimeInterval
          intervalSelectedButton={intervalSelectedButton}
          selectedStationData={selectedStationData}
        />

        <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  //chuva acumulada das 24h
  if (selectedStationData === "rain" && intervalSelectedButton === 4) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>
          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677" }}>Dia: {dayValue()}h</h2>
          </label>
          <br />
          <p>Escolha o horário:</p>
          <Slider
            min={0}
            max={4}
            step={1}
            marks={Object.fromEntries(
              twentyFourAccumulatedRainData
                ?.filter((entry) => entry.cdStation === "A001")
                .map((value, index) => [
                  index,
                  {
                    label: hourRound(value.hrMeasurement) + "h",
                  },
                ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={0}
            onChange={(value) => {
              if (Array.isArray(value)) return;

              setAccumulatedTwentyHour(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
        {/* <TimeInterval
          intervalSelectedButton={intervalSelectedButton}
          selectedStationData={selectedStationData}
        />
        <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  //chuva acumulada das 48h
  if (selectedStationData === "rain" && intervalSelectedButton === 5) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>
          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677" }}>Dia: {dayValue()}h</h2>
          </label>
          <br />
          <p>Escolha o horário:</p>
          <Slider
            min={0}
            max={2}
            step={1}
            marks={Object.fromEntries(
              fortyEightAccumulatedRainData
                ?.filter((entry) => entry.cdStation === "A001")
                .map((value, index) => [
                  index,
                  {
                    label: hourRound(value.hrMeasurement) + "h",
                  },
                ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={0}
            onChange={(value) => {
              if (Array.isArray(value)) return;

              setAccumulatedFortyEight(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
        {/* <TimeInterval
          intervalSelectedButton={intervalSelectedButton}
          selectedStationData={selectedStationData}
        />
        <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  //chuva acumulada das 72h
  if (selectedStationData === "rain" && intervalSelectedButton === 6) {
    return (
      <>
        <div className={styles.TimeIntervalButtons}>
          <p>Chuva Acumulada:</p>
          {rainButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677" }}>Dia: {dayValue()}h</h2>
          </label>
          <br />
          <p>Escolha o horário:</p>
          <Slider
            min={0}
            max={2}
            step={1}
            marks={Object.fromEntries(
              seventyTwoAccumulatedRainData
                ?.filter((entry) => entry.cdStation === "A001")
                .map((value, index) => [
                  index,
                  {
                    label: hourRound(value.hrMeasurement) + "h",
                  },
                ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={0}
            onChange={(value) => {
              if (Array.isArray(value)) return;

              setAccumulatedSeventyTwo(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
        {/* <div>
          <TimeInterval
            intervalSelectedButton={intervalSelectedButton}
            selectedStationData={selectedStationData}
          />
        </div> */}
      </>
    );
  }

  //Temperatura Minima Instantânea
  if (selectedStationData === "tmpmin" && intervalSelectedButton === 1) {
    return (
      <>
        <div className={styles.tmpMinButtons}>
          {tmpMinButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677", marginBottom: "3px" }}>
              Dia: {dayValue()}
            </h2>
          </label>

          <p>Temperatura mínima por intervalos de uma hora.</p>
          <p style={{ fontWeight: "bold", fontSize: "12px" }}>
            Escolha o horário:
          </p>
          <Slider
            min={0}
            max={23}
            step={1}
            marks={Object.fromEntries(
              currentDay?.map((value, index) => [
                index,
                {
                  label: hourRound(value.hrMeasurement) + "h",
                },
              ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={stationDayValue}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              setStationDayValue(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
      </>
    );
  }
  //Temperatura Minima das ultimas 24h
  if (selectedStationData === "tmpmin" && intervalSelectedButton === 2) {
    return (
      <>
        <div className={styles.rainMinSelected}>
          {tmpMinButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.TempMinDivSelected}>
          <label>
            <h2 style={{ color: "#032677", marginBottom: "10px" }}>
              Dia: {formatDate(currentDay?.[23]?.dtMeasurement)}
            </h2>
            <p style={{ fontSize: "12px" }}>
              Temperatura mínima das ultimas 24h
            </p>
          </label>
          <br />
        </div>
      </>
    );
  }

  //Temperatura Máxima Instantânea
  if (selectedStationData === "tmpmax" && intervalSelectedButton === 1) {
    return (
      <>
        <div className={styles.tmpMinButtons}>
          {tmpMaxButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.TimeIntervalButtons}></div>
        <div className={styles.StationTimeRange}>
          <label>
            <h2 style={{ color: "#032677", borderBottom: "3px" }}>
              Dia: {dayValue()}
            </h2>
            <p>Temperatura máxima por intervalos de uma hora.</p>
            <p style={{ fontWeight: "bold" }}>Escolha o horário:</p>
          </label>

          <Slider
            min={0}
            max={23}
            step={1}
            marks={Object.fromEntries(
              currentDay?.map((value, index) => [
                index,
                {
                  label: hourRound(value.hrMeasurement) + "h",
                },
              ]) ?? [] //a propriedade currentDay é undefined, a propriedade marks não aceita undefined, então usamos o operador ?? para se caso for undefined ele retorne um array vazio.
            )}
            defaultValue={stationDayValue}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              setStationDayValue(value);
            }}
            handleStyle={{
              backgroundColor: "#031e4c",
              borderColor: "#031e4c",
            }}
            trackStyle={{ backgroundColor: "#578B18", padding: "3px" }}
            railStyle={{ backgroundColor: "#031e4c" }}
            dotStyle={{ backgroundColor: "#031e4c" }}
            activeDotStyle={{ backgroundColor: "#578B18" }}
          />
        </div>
      </>
    );
  }
  // Temperatura máxima das ultimas 24h
  if (selectedStationData === "tmpmax" && intervalSelectedButton === 2) {
    return (
      <>
        <div className={styles.rainMinSelected}>
          {tmpMaxButtons.map((button) => (
            <button
              key={button.value}
              style={
                intervalSelectedButton === button.value
                  ? {
                      background: "#bee6ff",
                      color: "#032677",
                    }
                  : {}
              }
              className={
                intervalSelectedButton === button.value &&
                styles.timeIntervalSelectedButton
              }
              onClick={() => {
                selectInterval(button.value);
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className={styles.TempMinDivSelected}>
          <label>
            <h2 style={{ color: "#032677", marginBottom: "10px" }}>
              Dia: {formatDate(currentDay?.[23]?.dtMeasurement)}
            </h2>
            <p>Temperatura máxima das ultimas 24h</p>
          </label>
          <br />
        </div>
      </>
    );
  }
  if (selectedStationData === "") return null;

  return null;
};

export default StationTimeRange;
