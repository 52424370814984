import React from "react";
import styles from "./ShowChilePlantsButton.module.css";

const ShowChilePlantsButton = ({
  toggleChileMarkers,
  renderChileMarkers,
}: {
  toggleChileMarkers: () => void;
  renderChileMarkers: boolean;
}) => {
  return (
    <>
      <div>
        <button
          type="button"
          className={
            renderChileMarkers === true
              ? styles.showChilePlantsButtonActive
              : styles.showChilePlantsButton
          }
          onClick={() => {
            toggleChileMarkers();
          }}
        >
          Hidroelétricas Chile
        </button>
      </div>
    </>
  );
};

export default ShowChilePlantsButton;
