import React, { useState, useEffect } from "react";
import styles from "./Redirect.module.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useAuth } from "oidc-react";

export const Redirect = () => {
  const [showFullDiv, setShowFullDiv] = useState(false);
  //objeto de autenticação, com os dados do mesmo.
  const auth = useAuth();

  useEffect(() => {
    let timer: NodeJS.Timeout; // Definindo o tipo explícito da variável timer como NodeJS.Timeout

    if (showFullDiv) {
      // Se showFullDiv for verdadeiro, inicia o timer para desativar a div após 5 segundos
      timer = setTimeout(() => {
        setShowFullDiv(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer); // Limpa o timer quando o componente é desmontado ou quando showFullDiv é alterado novamente
    };
  }, [showFullDiv]);

  const handleButtonClick = () => {
    setShowFullDiv(!showFullDiv); // Inverte o valor de showFullDiv quando o botão é clicado
  };

  return (
    <div>
      <div className={styles.buttonDiv}>
        <button onClick={handleButtonClick}>
          <MdOutlineKeyboardArrowDown className={styles.inactiveIcon} />
        </button>
      </div>
      <div
        className={`${styles.redirectDiv} ${
          showFullDiv ? styles.active : styles.inactive
        }`}
      >
        <a
          href={`https://inteligencia.safiraenergia.com.br?user=${auth.userData?.profile.email}`}
        >
          <button style={{ width: "100%" }}></button>
        </a>
        <div className={styles.buttonDivActive}>
          <button onClick={handleButtonClick}>
            <MdOutlineKeyboardArrowDown />
          </button>
        </div>
      </div>
    </div>
  );
};
