import React from "react";
import { Popup } from "react-map-gl";

const ChilePlantsPopup = ({
  chilePlants,
  changePopupChile,
  popupChileData,
}: {
  chilePlants: ChilePlantsType[] | undefined;
  changePopupChile: (entry?: ChilePlantsType) => void;
  popupChileData: ChilePlantsType | undefined;
}) => {
  if (popupChileData) {
    return (
      <>
        {popupChileData && (
          <Popup
            latitude={popupChileData.latitude}
            longitude={popupChileData.longitude}
            onClose={() => changePopupChile(undefined)}
            style={{ borderRadius: "5px" }}
            anchor={"bottom"}
            closeOnMove={true}
            key={popupChileData.id}
            closeButton={false}
          >
            <h4>{popupChileData.name}</h4>
            <p>Bacia: {popupChileData.bowl}</p>
            <p>Status: {popupChileData.status}</p>
            <p>Região: {popupChileData.region}</p>
            <p>Fonte: {popupChileData.font}</p>
            <p>Empresa: {popupChileData.company}</p>
          </Popup>
        )}
      </>
    );
  }
  return null;
};

export default ChilePlantsPopup;
