import React from "react";
import { Popup } from "react-map-gl";

//este popup recebe dois tipos de dados, então o pop'upcemaden está definido como any para que ocorra a verificação que ou ele é any ou cemadenDataType, porque o onClick no map retorna um tipo completamente diferente que não pode ser exportado, então defini como any e assim acesso o tipo retornado do mapa e quando é feita a searchbar ela retorna no popup o tipo cemadendatatyope, e assim acesso ele também.

const CemadenPopup = ({
  popupCemaden,
  setPopupCemaden,
}: {
  popupCemaden: any | CemadenDataType;
  setPopupCemaden: (entry: any | CemadenDataType) => void;
}) => {
  if (typeof popupCemaden === "object" && "stationCode" in popupCemaden) {
    console.log(popupCemaden);
    return (
      <Popup
        key={popupCemaden.stationCode}
        //popupcemaden está como any porque é tipo GeojsonProperties, e ele não tem as propriedades necessárias, por isso estamos indicando pra ele quais são.
        latitude={popupCemaden.latitude}
        longitude={popupCemaden.longitude}
        closeOnClick={true}
        closeOnMove={true}
        onClose={() => {
          setPopupCemaden(undefined);
        }}
        closeButton={false}
      >
        <h4>{popupCemaden.stationName}</h4>
        <p>Cidade: {popupCemaden.city}</p>
        <p>Código estação: {popupCemaden.stationCode}</p>
      </Popup>
    );
  } else {
    return (
      <Popup
        key={popupCemaden.properties.stationCode}
        //popupcemaden está como any porque é tipo GeojsonProperties, e ele não tem as propriedades necessárias, por isso estamos indicando pra ele quais são.
        latitude={popupCemaden.geometry.coordinates[1]}
        longitude={popupCemaden.geometry.coordinates[0]}
        closeOnClick={true}
        closeOnMove={true}
        onClose={() => {
          setPopupCemaden(undefined);
        }}
        closeButton={false}
      >
        <h4>{popupCemaden.properties.stationName}</h4>
        <p>Cidade: {popupCemaden.properties.city}</p>
        <p>Código estação: {popupCemaden.properties.stationCode}</p>
        <p>Acumulado: {popupCemaden.properties.accumulated}</p>
      </Popup>
    );
  }
};

export default CemadenPopup;
