import React from "react";
import styles from "./LoadingScreen.module.css";

const LoadingScreen = () => {
  return (
    <div className={styles.LoadingScreenDiv}>
      <div className={styles.CloudAnimation}></div>
      <h1 className={styles.LoadingText}>Carregando...</h1>
    </div>
  );
};

export default LoadingScreen;
