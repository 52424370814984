import React, { useState, useEffect, useCallback } from "react";
import { MapRef } from "react-map-gl";
import "./SearchBar.css";
import { BsSearch } from "react-icons/bs";
import { GrClose } from "react-icons/gr";

const SearchBarChilePlants = ({
  chilePlants,
  setPopupChileData,
  reference,
}: {
  chilePlants: ChilePlantsType[] | undefined;
  setPopupChileData: (entry?: ChilePlantsType) => void;
  reference: React.RefObject<MapRef>;
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filterSearch, setFilterSearch] = useState<ChilePlantsType[]>([]);
  const [selectedItem, setSelectedItem] = useState<ChilePlantsType>();

  const changePosition = (newPosition: ChilePlantsType) => {
    if (newPosition !== selectedItem) {
      setPopupChileData(newPosition);
    }

    const latitude = newPosition.latitude;
    const longitude = newPosition.longitude;

    reference.current?.jumpTo({
      center: [longitude, latitude],
    });

    reference.current?.setZoom(10);
  };

  const handleFilter = useCallback(() => {
    if (chilePlants) {
      const newFilter = chilePlants
        .filter((value) => {
          const stationName = value.name.toLowerCase();
          const inputValueLower = inputValue.toLowerCase();

          return stationName.indexOf(inputValueLower) === 0;
        })
        .sort((a, b) => {
          const stationA = a.name.toLowerCase();
          const stationB = b.name.toLowerCase();
          const inputValueLower = inputValue.toLowerCase();

          const distanceA = Math.abs(stationA.indexOf(inputValueLower));
          const distanceB = Math.abs(stationB.indexOf(inputValueLower));

          if (distanceA === distanceB) {
            return stationA.localeCompare(stationB);
          }

          return distanceA - distanceB;
        })
        .filter((value, index, self) => {
          return self.findIndex((v) => v.name === value.name) === index;
        });

      setFilterSearch(newFilter);
    }
  }, [chilePlants, inputValue]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  return (
    <>
      <div className="firstDiv">
        <input
          type="text"
          className="searchBar"
          placeholder="Busque pelo nome da usina"
          onChange={(event) => setInputValue(event.target.value)}
          value={inputValue}
        />
        <span className="searchSpan">
          <span>{<BsSearch className="iconSearch" />}</span>
        </span>
        <button
          className="clearButton"
          onClick={() => {
            setInputValue("");
          }}
        >
          <div className="iconSearch">
            <span>{<GrClose />}</span>
          </div>
        </button>
        {inputValue && (
          <div className="dataResult">
            {filterSearch.map((value) => {
              return (
                <div
                  key={value.id}
                  className="dataItem"
                  onClick={(event) => {
                    event.stopPropagation();
                    changePosition(value);
                    setInputValue("");
                    setPopupChileData(value);
                  }}
                >
                  <p>{value.name}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBarChilePlants;
